import { BaseClientServer } from '@/entities/client/base';
import { SortDirection } from '@/types/meta';
import { Nullable } from '@/types/values';

import { BaseLeadServer, LeadStatus } from '../base';

export type CrmRejectionInfoServer = {
  lead_id: string;
  manager: Nullable<{
    name: Nullable<string>;
    phone: Nullable<string>;
  }>;
  agency: Nullable<{
    title: Nullable<string>;
  }>;
  message?: Nullable<string>;
  hold_date?: Nullable<string>;
};

export type LeadServer = BaseLeadServer & {
  client: BaseClientServer;
  crm_rejection_info: Nullable<CrmRejectionInfoServer>;
};

export type LeadListServer = {
  bids: LeadServer[];
  total: number;
};

export type BaseLeadListServer = {
  bids: BaseLeadServer[];
  total: number;
};

export enum SortPayloadFields {
  created_at = 'created_at',
  hold_date = 'hold_date',
}

export type SortPayload = {
  field: SortPayloadFields;
  direction: Exclude<SortDirection, 'none'>;
};

export type HoldDatePayload = {
  from: Nullable<string>;
  to: Nullable<string>;
};

export type LeadListPayload = {
  pagination: {
    limit: number;
    offset: number;
  };
  filters: {
    manager_ids: Nullable<number[]>;
    statuses: Nullable<LeadStatus[]>;
    project_ids: Nullable<number[]>;
    place_types: Nullable<string[]>;
    hold_date: Nullable<HoldDatePayload>;
  };
  sort: Nullable<SortPayload[]>;
  search: Nullable<string>;
};

export type ManagerLeadCreationPayload = {
  manager_id: Nullable<number>;
  sub_agent: Nullable<{
    phone: string;
    name: string;
  }>;
};
