import cn from 'classnames';
import * as React from 'react';

import s from './DoubleField.module.scss';

type DoubleFieldProps = React.PropsWithChildren<{
  className?: string;
}>;

const DoubleField = ({ children, className }: DoubleFieldProps): React.ReactElement<DoubleFieldProps> => {
  return <div className={cn(s['double-field'], className)}>{children}</div>;
};

export default DoubleField;
