import { Nullable } from '@/types/values';

import { RouteSegment } from './constants';
import { ClientPageTabs, SelectionPageTabs } from './subtabs';

export const routerUrls = {
  root: '/',
  leadList: {
    mask: `/${RouteSegment.leads}`,
    create: () => `/${RouteSegment.leads}`,
  },
  clientList: {
    mask: `/${RouteSegment.clients}`,
    create: () => `/${RouteSegment.clients}`,
  },
  clientDetail: {
    mask: `/${RouteSegment.client}/:clientId/:tab?`,
    create: (clientId: number | string, tab?: ClientPageTabs) =>
      `/${RouteSegment.client}/${clientId}${tab ? '/' + tab : ''}`,
  },
  employeeList: {
    mask: `/${RouteSegment.employees}`,
    create: () => `/${RouteSegment.employees}`,
  },
  agencyProfile: {
    mask: `/${RouteSegment.agency}`,
    create: () => `/${RouteSegment.agency}`,
  },
  booking: {
    mask: `/${RouteSegment.booking}`,
    create: () => `/${RouteSegment.booking}`,
  },
  rateCard: {
    mask: `/${RouteSegment.rateCard}`,
    create: () => `/${RouteSegment.rateCard}`,
  },
  clientLotsSelection: {
    mask: `/${RouteSegment.clientLotsSelection}`,
    create: (selectionId: string, clientId: Nullable<string>) =>
      `/${RouteSegment.clientLotsSelection}?selectionId=${selectionId}${clientId ? `&clientId=${clientId}` : ''}`,
  },
  lots: {
    mask: `/${RouteSegment.lots}`,
    create: () => `/${RouteSegment.lots}`,
  },
  selections: {
    mask: `/${RouteSegment.selections}`,
    create: () => `/${RouteSegment.selections}`,
  },
  selectionDetail: {
    mask: `/${RouteSegment.selections}/:selectionId/:tab?`,
    create: (selectionId: number, tab?: SelectionPageTabs) =>
      `/${RouteSegment.selections}/${selectionId}${tab ? '/' + tab : ''}`,
  },
};
