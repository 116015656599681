import * as React from 'react';

import { useToggleState } from '@kts-front/hooks';

import Modal, { ModalVariant } from '../Modal';
import { ConfirmCloseButtonPosition } from '../Modal/Confirm/Confirm';

import Content from './Content';

import s from './TemporaryInfoModal.module.scss';

const TemporaryInfoModal: React.FC = () => {
  const { close, opened, open } = useToggleState();

  React.useEffect(() => {
    open();
  }, []);

  return (
    <Modal
      className={s.modal}
      contentClassName={s.modal__content}
      variant={ModalVariant.Confirm}
      opened={opened}
      onClose={close}
      ariaLabel="Изменения проекта Republic"
      withBackdrop
      closeButtonPosition={ConfirmCloseButtonPosition.within}
    >
      <Content onSubmitClick={close} />
    </Modal>
  );
};

export default TemporaryInfoModal;
