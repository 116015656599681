import * as React from 'react';

import { BottomRightWindow, BottomRightWindowProps } from './BottomRightWindow';
import Confirm, { ConfirmProps } from './Confirm';
import Overlay, { OverlayProps } from './Overlay';
import RightWindow, { RightWindowProps } from './RightWindow';
import TopWindow, { TopWindowProps } from './TopWindow';
import { ModalVariant } from './types';

type VariantProps =
  | ({
      variant: ModalVariant.Confirm;
    } & ConfirmProps)
  | ({
      variant: ModalVariant.RightWindow;
    } & RightWindowProps)
  | ({
      variant: ModalVariant.BottomRight;
    } & BottomRightWindowProps)
  | ({
      variant: ModalVariant.TopWindow;
    } & TopWindowProps);

export type ModalProps = OverlayProps & VariantProps;

const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
  const isBottomRight = props.variant === ModalVariant.BottomRight;

  return (
    <Overlay {...props} withBackdrop={isBottomRight ? false : props.withBackdrop} withoutEvents={isBottomRight}>
      {props.variant === ModalVariant.Confirm && <Confirm {...props}>{children}</Confirm>}
      {props.variant === ModalVariant.RightWindow && <RightWindow {...props}>{children}</RightWindow>}
      {props.variant === ModalVariant.TopWindow && <TopWindow {...props}>{children}</TopWindow>}
      {props.variant === ModalVariant.BottomRight && <BottomRightWindow {...props}>{children}</BottomRightWindow>}
    </Overlay>
  );
};

export default Modal;
