import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/stores/global/RootStore';

import Modal, { ModalVariant } from '../Modal';
import { ConfirmCloseButtonPosition } from '../Modal/Confirm/Confirm';

import Content from './Content';

import s from './PWATutorialModal.module.scss';

const PWATutorialModal: React.FC = () => {
  const {
    PWATutorialModalStore: { PWATutorialModal, close },
  } = useRootStore().modalsStore;

  return (
    <Modal
      className={s.modal}
      contentClassName={s.modal__content}
      variant={ModalVariant.Confirm}
      opened={PWATutorialModal.isOpen}
      onClose={close}
      ariaLabel="Установите PWA"
      withBackdrop
      closeButtonPosition={ConfirmCloseButtonPosition.within}
    >
      <Content />
    </Modal>
  );
};

export default observer(PWATutorialModal);
