import { ClientServer, IClient } from '@/entities/client/list';
import { BaseLeadServer } from '@/entities/lead/base';

import { BaseClientModel } from './BaseClientModel';
import { BaseLeadModel } from './BaseLeadModel';
import { PhoneNumber } from './PhoneNumber';

export class ClientModel extends BaseClientModel implements IClient {
  readonly bids;

  constructor({ bids, ...props }: IClient) {
    super(props);
    this.bids = bids;
  }

  static fromJson(raw: ClientServer): ClientModel {
    const phone = PhoneNumber.fromJson(raw.phone);

    const bids = raw.bids.map((bid: BaseLeadServer) => BaseLeadModel.baseLeadFromJson(bid));

    return new ClientModel({
      id: `${raw.id}`,
      clientId: raw.id,
      firstName: raw.first_name,
      lastName: raw.last_name,
      patronymic: raw.patronymic,
      phone,
      email: raw.email ?? '',
      inn: raw.inn,
      rawPhone: raw.phone,
      bids,
    });
  }
}
