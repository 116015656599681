import cn from 'classnames';
import * as React from 'react';

import Typo, { TypoView } from '@/components/Typo';

import Logo from './Logo';

import s from './PageTitle.module.scss';

type Props = {
  className?: string;
  captionClassName?: string;
  titleView?: TypoView;
  hasLargeLogo?: boolean;
};

const PageTitle: React.FC<Props> = ({
  className,
  captionClassName,
  titleView = TypoView.caption,
  hasLargeLogo = false,
}) => {
  return (
    <div className={cn(s.title, className)}>
      <Logo isLarge={hasLargeLogo} />
      <Typo className={cn(s.title_caption, captionClassName)} color="text-grey" view={titleView} uppercase>
        Личный <br />
        кабинет партнера
      </Typo>
    </div>
  );
};

export default PageTitle;
