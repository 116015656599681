export enum FixationCreationMode {
  // Самый полный режим модалки с возможностью выбрать клиента и создания заявки. Для страницы со списками клиентов
  full = 'full',
  // скипаем выбора клиента, тк он уже у нас выбран. Для создания заявки с деталке клиента и из истекшей заявки
  skipClient = 'skipClient',
}

export enum FixationCreationStage {
  clientSearch = 'clientSearch',
  // редактирование или создание клиента
  clientForm = 'clientForm',
  leadForm = 'leadForm',
  result = 'result',
}
