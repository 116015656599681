import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { useShowAlerts } from '@/components/Alerts';
import Alerts from '@/components/Alerts/Alerts';
import Header from '@/components/Header';
import LoadingStub from '@/components/LoadingStub';
import LotDetailModal from '@/components/LotDetailModal';
import Modals from '@/components/Modals';
import NotificationsSubscribeModal from '@/components/NotificationsSubscribeModal';
import NotificationsSubscribePortal from '@/components/NotificationsSubscribePopup/NotificationsSubscribePortal';
import RewardBar from '@/components/RewardBar';
import { RouteSegment } from '@/configs/routes';
import { useInitPushSubscribe } from '@/stores/global/PushSubscribeStore';
import { useRootStore } from '@/stores/global/RootStore';

const App: React.FC = () => {
  const {
    pushSubscribeStore,
    userStore: { authorized, isBlockedUser },
    uiStore: { isPhone },
  } = useRootStore();

  const isLoginPage = useMatch('/');
  const isRewardPage = useMatch(`/${RouteSegment.rateCard}`);
  const isClientLotsSelectionPage = useMatch(`/${RouteSegment.clientLotsSelection}`);

  const showHeader = !isLoginPage && !isClientLotsSelectionPage;
  const showRewardBar = !isBlockedUser && !isRewardPage && showHeader;

  const showAlerts = useShowAlerts();

  useInitPushSubscribe(pushSubscribeStore);

  return (
    <>
      {showHeader && <Header />}
      <RewardBar isBarShown={showRewardBar} />
      <React.Suspense fallback={<LoadingStub local />}>
        <Outlet />
      </React.Suspense>
      {showAlerts && <Alerts />}
      {authorized && <Modals />}
      <LotDetailModal />
      {pushSubscribeStore.canUsePushs && (isPhone ? <NotificationsSubscribeModal /> : <NotificationsSubscribePortal />)}
    </>
  );
};

export default observer(App);
