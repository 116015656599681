import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Modal, { ModalVariant } from '@/components/Modal';
import Typo, { TypoView } from '@/components/Typo';
import { callCenterFormattedNumber, callCenterNumber } from '@/configs/app';
import { useRootStore } from '@/stores/global/RootStore';

import s from './MeetingNoticeModal.module.scss';

const MeetingNoticeModal: React.FC = () => {
  const { meetingNoticeModal } = useRootStore().modalsStore;

  return (
    <Modal
      variant={ModalVariant.Confirm}
      opened={meetingNoticeModal.isOpen}
      onClose={meetingNoticeModal.close}
      primaryButtonProps={{ text: 'Хорошо', onClick: meetingNoticeModal.close }}
      ariaLabel={`Для записи на встречу позвоните по номеру: ${callCenterFormattedNumber}`}
      title={
        <Typo view={TypoView.h5}>
          Для записи на встречу,
          <br />
          позвоните по номеру:
          <a href={`tel:${callCenterNumber}`} className={s.notice__phone}>
            {callCenterFormattedNumber}
          </a>
        </Typo>
      }
    />
  );
};

export default observer(MeetingNoticeModal);
