import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/stores/global/RootStore';

import Portal from '../Modal/Portal';

import PopupContent from './PopupContent';

import s from './NotificationsSubscribePortal.module.scss';

const NotificationsSubscribePortal: React.FC = () => {
  const { pushSubscribeStore } = useRootStore();

  return (
    <Portal>
      <AnimatePresence>
        {pushSubscribeStore.modal.isOpen && (
          <motion.div
            className={s.portal}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <PopupContent
              trySubscribe={pushSubscribeStore.trySubscribe}
              rejectSubscribe={pushSubscribeStore.closeModal.bind(pushSubscribeStore)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default observer(NotificationsSubscribePortal);
