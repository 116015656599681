import * as React from 'react';

import Modal, { ModalVariant } from '@/components/Modal';
import { formaEmployees } from '@/entities/formaEmployee';

import Employee from './Employee';

import s from './ErrorModal.module.scss';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
};

const ErrorModal: React.FC<Props> = ({ opened, onClose }) => {
  return (
    <Modal
      variant={ModalVariant.Confirm}
      opened={opened}
      onClose={onClose}
      primaryButtonProps={{ text: 'Хорошо', onClick: onClose }}
      ariaLabel="Для записи на встречу позвоните по номеру: +7 495 182-65-28"
      title="Ошибка при создании заявки, обратитесь к одному из наших сотрудников:"
    >
      {formaEmployees.map((employee, index) => (
        <Employee className={s.employee} key={index} employee={employee} />
      ))}
    </Modal>
  );
};

export default ErrorModal;
