import * as React from 'react';

import { FixationCreationStage } from '@/stores/global/FixationModalStore';

import ClientFixation from './ClientFixation';
import FixationResult from './FixationResult';
import LeadFixation from './LeadFixation';

export const COMP_MAP: Record<FixationCreationStage, React.ReactNode> = {
  [FixationCreationStage.clientSearch]: <ClientFixation />,
  [FixationCreationStage.clientForm]: <ClientFixation />,
  [FixationCreationStage.leadForm]: <LeadFixation />,
  [FixationCreationStage.result]: <FixationResult />,
};
