import { computed, makeObservable } from 'mobx';

import { ManagerLeadCreationPayload } from '@/entities/lead/list';
import { UserRole } from '@/entities/user';
import { CheckboxModel } from '@/models/CheckboxModel';
import { InputModel } from '@/models/InputModel';
import { IDictionariesStore } from '@/stores/global/DictionariesStore';
import { IRootStore } from '@/stores/global/RootStore';
import { validateNotEmpty, validatePhoneNumber } from '@/utils/validators';

import { ManagerFormFields, WITH_SUBAGENT_FIELDS, WITHOUT_SUBAGENT_FIELDS } from './config';

type Params = {
  rootStore: IRootStore;
};

// TODO: проверить, просто перенесла из CreteClientFormModel. Проверить, что все поля используются
export class ManagerFormModel {
  readonly [ManagerFormFields.managerId] = new InputModel<number | null>({
    name: 'manager_id',
    caption: 'Выберите менеджера',
    required: false,
    initialValue: null,
  });

  readonly [ManagerFormFields.isSubAgent] = new CheckboxModel({
    name: 'is_sub_agent',
    caption: 'Клиент привлечён с помощью субагента',
    initialValue: 'isSubAgent',
    checked: false,
  });

  readonly [ManagerFormFields.subAgentFullName] = new InputModel({
    name: 'sub_agent_full_name',
    caption: 'Фамилия, имя субагента',
    initialValue: '',
    validators: [validateNotEmpty()],
  });

  readonly [ManagerFormFields.subAgentPhone] = new InputModel({
    name: 'sub_agent_phone',
    caption: 'Телефон',
    initialValue: '',
    validators: [validateNotEmpty(), validatePhoneNumber()],
  });

  private _rootStore: IRootStore;

  constructor({ rootStore }: Params) {
    this._rootStore = rootStore;

    makeObservable<this, '_fieldsToValidate' | '_requiredFields' | '_finalManagerId'>(this, {
      isError: computed,
      isManagerRole: computed,
      isRequiredFieldsFilled: computed,
      _finalManagerId: computed,
      _fieldsToValidate: computed,
      _requiredFields: computed,
    });
  }

  get dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get isError(): boolean {
    let isError = false;

    this._fieldsToValidate.forEach((field) => {
      if (this[field].isError) {
        isError = true;
      }
    });

    return isError;
  }

  get isManagerRole(): boolean {
    return this._rootStore.userStore.user?.role === UserRole.manager;
  }

  get isRequiredFieldsFilled(): boolean {
    return this._requiredFields.every((field) => !!this[field].value);
  }

  scrollToErrorField = () => {
    const errorField = this._fieldsToValidate.find((field) => this[field].isError);

    if (errorField) {
      this[errorField].scrollToField();
    }
  };

  changeIsSubAgent = (checked: boolean): void => {
    this[ManagerFormFields.isSubAgent].onChange(checked, 'isSubAgent');

    this[ManagerFormFields.subAgentFullName].changeRequired(checked);
    this[ManagerFormFields.subAgentPhone].changeRequired(checked);
    this[ManagerFormFields.managerId].changeRequired(!checked);

    this[ManagerFormFields.subAgentFullName].resetError();
    this[ManagerFormFields.subAgentPhone].resetError();
  };

  validate = (): boolean => {
    this._fieldsToValidate.forEach((field) => {
      this[field].validate();
    });

    if (this.isError) {
      this.scrollToErrorField();
    }

    return this.isError;
  };

  toJson = (): ManagerLeadCreationPayload => {
    return {
      manager_id: this._finalManagerId,
      sub_agent: this[ManagerFormFields.isSubAgent].checked
        ? {
            name: this[ManagerFormFields.subAgentFullName].value,
            phone: this[ManagerFormFields.subAgentPhone].value,
          }
        : null,
    };
  };

  reset = (): void => {
    this[ManagerFormFields.isSubAgent].onChange(false, 'isSubAgent');
    this._fieldsToValidate.forEach((field) => {
      this[field].reset();
    });
  };

  private get _finalManagerId(): number | null {
    if (this.isManagerRole) {
      return this._rootStore.userStore.user?.id ?? null;
    }

    if (this[ManagerFormFields.isSubAgent].checked) {
      return this._rootStore.userStore.user?.id ?? null;
    }

    return this[ManagerFormFields.managerId].value || this._rootStore.userStore.user?.id || null;
  }

  private get _fieldsToValidate(): ManagerFormFields[] {
    return this[ManagerFormFields.isSubAgent].checked ? WITH_SUBAGENT_FIELDS : WITHOUT_SUBAGENT_FIELDS;
  }

  private get _requiredFields(): ManagerFormFields[] {
    return this._fieldsToValidate.filter((field) => this[field].required) as ManagerFormFields[];
  }
}
