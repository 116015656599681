import { BaseClientServer, IBaseClient } from '@/entities/client/base';
import { getFullName, getFullNameWithPatronymic } from '@/utils/getFullName';

import { PhoneNumber } from './PhoneNumber';

export class BaseClientModel implements IBaseClient {
  readonly id;
  readonly clientId;
  readonly firstName;
  readonly lastName;
  readonly patronymic;
  readonly phone;
  readonly email;
  readonly inn;
  readonly rawPhone;

  constructor({
    id,

    firstName,
    lastName,
    phone,
    email,
    inn,
    patronymic,
    rawPhone,
    clientId,
  }: IBaseClient) {
    this.id = id;
    this.clientId = clientId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.patronymic = patronymic;
    this.phone = PhoneNumber.format(phone);
    this.email = email;
    this.inn = inn;
    this.rawPhone = rawPhone;
  }

  get clientFullNameAndPhone(): string {
    return `${this.clientFullName}, ${this.phone}`;
  }

  get clientFullName(): string {
    return getFullName({ firstName: this.firstName, lastName: this.lastName });
  }
  get clientFullNameWithPatronymic(): string {
    return getFullNameWithPatronymic(this);
  }

  get shortClientFullName(): string {
    return getFullName({ firstName: this.firstName, lastName: this.lastName, reversed: false, short: true });
  }

  static fromJson(raw: BaseClientServer): BaseClientModel {
    const phone = PhoneNumber.fromJson(raw.phone);

    return new BaseClientModel({
      id: `${raw.id}`,
      clientId: raw.id,
      firstName: raw.first_name,
      lastName: raw.last_name,
      patronymic: raw.patronymic,
      phone,
      email: raw.email ?? '',
      inn: raw.inn,
      rawPhone: raw.phone,
    });
  }
}
