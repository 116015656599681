import { Nullable } from '@/types/values';

import { LeadStatus, SubAgent } from './server';

export enum BaseLeadFields {
  id = 'id',
  comment = 'comment',
  createdDate = 'createdDate',
  expiryDate = 'expiryDate',
  managerId = 'managerId',
  managerFullName = 'managerFullName',
  placeType = 'placeType',
  project = 'project',
  status = 'status',
  subAgent = 'subAgent',
}

export interface IBaseLead {
  [BaseLeadFields.managerId]: number;
  [BaseLeadFields.managerFullName]: string;
  [BaseLeadFields.id]: number;
  [BaseLeadFields.status]: LeadStatus;
  [BaseLeadFields.placeType]: string;
  [BaseLeadFields.createdDate]: string;
  [BaseLeadFields.expiryDate]?: string;
  [BaseLeadFields.project]: string;
  [BaseLeadFields.comment]: string;
  [BaseLeadFields.subAgent]: Nullable<SubAgent>;
}
