import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import LeadStatusMarker from '@/components/LeadStatusMarker';
import Typo, { TypoWeight } from '@/components/Typo';
import { ILead } from '@/entities/lead/list';
import { useUiStore } from '@/stores/global/RootStore';

import s from './LeadName.module.scss';

type Props = {
  item: ILead;
  className?: string;
  textClassName?: string;
  statusClassName?: string;
};

const LeadName: React.FC<Props> = ({ item, className, textClassName, statusClassName }) => {
  const { isPhone } = useUiStore();

  return (
    <div className={cn(s.lead, className)}>
      <div className={cn(s.lead_status, s[`lead_${item.status}`], statusClassName)} />
      <LeadStatusMarker className={cn(s.lead_status, statusClassName)} size={isPhone ? 8 : 12} status={item.status} />
      <Typo className={cn(s.lead_name, textClassName)} weight={TypoWeight.medium}>
        {item.client.clientFullName}
      </Typo>
    </div>
  );
};

export default observer(LeadName);
