import { LoadingStageModel } from '@/models/LoadingStageModel';
import { CardLotModel } from '@/models/lots/CardLotModel';
import { ToggleModel } from '@/models/ToggleModel';
import { ValueModel } from '@/models/ValueModel';
import { Nullable } from '@/types/values';

export interface IModalButtonProps {
  text: string;
  onClick: VoidFunction | undefined;
  isLoading: boolean;
  disabled: boolean;
}

export interface ILotDetailModalStore {
  detailLotModal: ToggleModel;
  detailLotModalTitle: string;
  detailLotModalButtonProps: IModalButtonProps;
  buttonText: string;
  get loadingStage(): LoadingStageModel;
  get lot(): ValueModel<Nullable<CardLotModel>>;
  open(params: OpenParams): void;
  close(): void;
  onClick: () => void;
  fetchLot(id: number): Promise<void>;
}

export enum LotDetailModalStoreOpenType {
  id = 'id',
  entity = 'entity',
}

type BaseOpenParams = Partial<{
  onClick: VoidFunction;
  buttonText: string;
  isButtonDisabled: boolean;
}>;

export type OpenParams =
  | ({ type: LotDetailModalStoreOpenType.id; value: number } & BaseOpenParams)
  | ({ type: LotDetailModalStoreOpenType.entity; value: CardLotModel } & BaseOpenParams);
