import * as React from 'react';

import { Color } from '@/types/colors';

import BaseClickable, { BaseClickableProps, ValueProp } from '../BaseClickable';
import Icon, { IconName } from '../Icon';

type IconButtonProps = BaseClickableProps<ValueProp> & {
  size?: string | number;
  color?: Color;
  iconRotation?: number;
  iconName: IconName;
};

const IconButton: React.FC<IconButtonProps> = ({ iconName, color, size, iconRotation, ...props }) => {
  return (
    <BaseClickable {...props} preventDefault stopPropagation>
      <Icon {...{ iconName, color, iconRotation, size }} />
    </BaseClickable>
  );
};

export default IconButton;
