import { computed, makeObservable } from 'mobx';

import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import { CreateClientFormModel } from '@/models/CreateClientFormModel';
import { CreateClientFormFields } from '@/models/CreateClientFormModel/config';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { ValueModel } from '@/models/ValueModel';
import { ApiErrorCode } from '@/types/meta';

type Params = {
  extraRequiredFields?: (
    | CreateClientFormFields.clientEmail
    | CreateClientFormFields.clientLastName
    | CreateClientFormFields.clientPatronymic
  )[];
  requestParams?: Record<string, unknown>;
};

export class ClientCreationModel {
  readonly clientForm: CreateClientFormModel;
  readonly submitStage = new LoadingStageModel();
  private readonly _isServerErrorModel = new ValueModel(false);
  private _submitRequest = apiStore.createRequest({
    method: 'POST',
    url: apiUrls.client.create,
  });
  private readonly _requestParams: Record<string, unknown>;

  constructor({ extraRequiredFields, requestParams }: Params) {
    this.clientForm = new CreateClientFormModel({ extraRequiredFields });
    this._requestParams = requestParams ?? {};

    makeObservable(this, {
      isFieldError: computed,
      isServerError: computed,
      isRequiredFieldsFilled: computed,
    });
  }

  get isRequiredFieldsFilled(): boolean {
    return this.clientForm.isRequiredFieldsFilled;
  }

  get isServerError(): boolean {
    return this._isServerErrorModel.value;
  }

  get isFieldError(): boolean {
    return this.clientForm.isError;
  }

  submit = async (): Promise<BaseResponse<{ id: number }>> => {
    const isClientFormError = this.validate();

    if (isClientFormError) {
      this.submitStage.error();

      return {
        isError: true,
      };
    }

    return this.createClient();
  };

  resetServerError = (): void => {
    this._isServerErrorModel.change(false);
  };

  reset = (): void => {
    this.clientForm.reset();
    this.resetServerError();
    this.submitStage.reset();
  };

  validate = (): boolean => {
    return this.clientForm.validate();
  };

  async createClient(id?: number): Promise<BaseResponse<{ id: number }>> {
    this.submitStage.loading();
    const clientJsonData = this.clientForm.toJson();
    const data =
      id == undefined
        ? {
            client: {
              ...clientJsonData,
            },
          }
        : {
            client_id: id,
          };

    const response = await this._submitRequest.call<{ id: number }>({
      data,
      params: this._requestParams,
    });

    if (!response.isError) {
      this.submitStage.success();

      return {
        isError: false,
        data: {
          id: response.data?.id,
        },
      };
    }

    this.submitStage.error();

    if (response.data?.data.code === ApiErrorCode.clientDuplicate) {
      this._errorDublicateHandling();

      return {
        isError: true,
      };
    }

    this._isServerErrorModel.change(true);

    return {
      isError: true,
    };
  }

  private _errorDublicateHandling(): void {
    this.clientForm.clientPhone.changeError('Такой клиент уже существует');
    this.clientForm.clientPhone.scrollToField();
  }
}
