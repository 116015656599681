import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { FixationCreationStage } from '@/stores/global/FixationModalStore';
import { useFixationModalStore } from '@/stores/global/RootStore';

import ClientForm from './ClientForm';
import ClientSearch from './ClientSearch';
import ClientSearchStub from './ClientSearchStub';

const ClientFixation: React.FC = () => {
  const [contentHeight, setContentHeight] = React.useState<number>(0);

  const { stage, isClientNotFound } = useFixationModalStore();

  // 226px - это высота экрана, которая исключает высоту самого бесконечного списка внутри модалки
  React.useEffect(() => {
    const updateMaxHeight = () => {
      setContentHeight(window.innerHeight - 226);
    };

    updateMaxHeight();
    window.addEventListener('resize', updateMaxHeight);

    return () => {
      window.removeEventListener('resize', updateMaxHeight);
    };
  }, []);

  return (
    <div>
      <ClientSearch listHeight={contentHeight} />
      {stage === FixationCreationStage.clientSearch && isClientNotFound && (
        <ClientSearchStub stubHeight={contentHeight} />
      )}
      {stage === FixationCreationStage.clientForm && <ClientForm />}
    </div>
  );
};

export default observer(ClientFixation);
