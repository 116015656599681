import * as React from 'react';

import Typo, { TypoElement, TypoView } from '@/components/Typo';

import OptionButton from '../OptionButton';
import { BaseSelectProps, ValuesProps } from '../types';

import { SelectHorizontalView } from './config';

import s from './SelectHorizontal.module.scss';

export type SelectHorizontalProps<V extends string | number> = BaseSelectProps<V> & {
  view?: SelectHorizontalView;
  buttonClassName?: string;
};

const SelectHorizontal = <V extends string | number>(
  {
    className,
    caption,
    onChange,
    options,
    values,
    multiple,
    view = SelectHorizontalView.default,
    buttonClassName,
  }: SelectHorizontalProps<V> & ValuesProps<V>,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement => {
  const isAllSelected = !values.size || values.size === options.length;
  const willAllSelected = multiple && values.size === options.length - 1;

  return (
    <div className={className} ref={ref}>
      {caption && (
        <Typo className={s.select_caption} view={TypoView.buttonSmall} element={TypoElement.h6}>
          {caption}
        </Typo>
      )}
      <div className={s.select_list}>
        <OptionButton
          className={buttonClassName}
          key="all_selected"
          checked={isAllSelected}
          onClick={onChange}
          view={view}
        >
          Все
        </OptionButton>
        {options.map(({ label, id }) => (
          <OptionButton
            className={buttonClassName}
            key={id}
            id={id}
            willAllSelected={willAllSelected}
            checked={values.has(id)}
            onClick={onChange}
            view={view}
          >
            {label}
          </OptionButton>
        ))}
      </div>
    </div>
  );
};

export default React.forwardRef(SelectHorizontal) as unknown as <V extends string | number>(
  props: SelectHorizontalProps<V> & ValuesProps<V> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof SelectHorizontal>;
