export const formatMoney = (amount: number | null, digitsAfter: number = 0): string => {
  const currencySymbol = '\u20BD';

  if (!amount) {
    return `0 ${currencySymbol}`;
  }

  const parts = Math.abs(amount).toFixed(digitsAfter).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return `${parts.join('.')} ${currencySymbol}`;
};
