import { observer } from 'mobx-react-lite';
import * as React from 'react';

import LeadResultContent from '@/components/leads/LeadResultContent';
import ResultModalStub from '@/components/ResultModalStub';
import { CreateClientResultType, CreateLeadResultType } from '@/entities/modal';
import { useFixationModalStore } from '@/stores/global/RootStore';

import { CLIENT_RESULT_CONTENT_PROPS_CONFIG } from './config';

const FixationResult: React.FC = () => {
  const { resultState } = useFixationModalStore();
  const crmRejectionInfo =
    resultState.value?.type === CreateLeadResultType.refused ? resultState.value.crmRejectionInfo : null;
  const stateType = resultState.value?.type || CreateClientResultType.successClient;
  const contentProps = CLIENT_RESULT_CONTENT_PROPS_CONFIG[stateType];

  const description = crmRejectionInfo?.message || contentProps.description;

  return (
    <ResultModalStub {...contentProps} description={description}>
      {crmRejectionInfo && <LeadResultContent data={crmRejectionInfo} />}
    </ResultModalStub>
  );
};

export default observer(FixationResult);
