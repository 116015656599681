import { computed, makeObservable, runInAction } from 'mobx';

import { noop } from '@kts-front/utils';

import { apiStore, apiUrls } from '@/api';
import { CardLotServer } from '@/entities/lots';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { CardLotModel } from '@/models/lots/CardLotModel';
import { ToggleModel } from '@/models/ToggleModel';
import { ValueModel } from '@/models/ValueModel';
import { Nullable } from '@/types/values';

import { ILotDetailModalStore, IModalButtonProps, LotDetailModalStoreOpenType, OpenParams } from './types';

export class LotDetailModalStore implements ILotDetailModalStore {
  readonly detailLotModal: ToggleModel = new ToggleModel();
  readonly lot = new ValueModel<Nullable<CardLotModel>>(null);
  readonly loadingStage = new LoadingStageModel();

  private readonly _modalButtonText = new ValueModel<string>('');
  private readonly _isModalButtonDisabled = new ValueModel<boolean | null>(null);

  /** Хранит переданный при открытии модалки обработчик нажатия на кнопку в модалке */
  private _onClickValue = new ValueModel<VoidFunction | undefined>(undefined);

  private readonly _request = apiStore.createRequest<CardLotServer>({ method: 'GET' });

  constructor() {
    makeObservable(this, {
      detailLotModalTitle: computed,
      detailLotModalButtonProps: computed,
      buttonText: computed,
    });
  }

  get detailLotModalTitle(): string {
    if (!this.lot.value) {
      return '';
    }

    return `${this.lot.value.bulk.block.name} •${'\u00A0'}${this.lot.value.rooms} ${this.lot.value.area}${'\u00A0'}м²`;
  }

  get buttonText(): string {
    return this._modalButtonText.value;
  }

  get detailLotModalButtonProps(): IModalButtonProps {
    return {
      text: this._modalButtonText.value,
      onClick: this._onClickValue.value,
      isLoading: this.loadingStage.isLoading,
      disabled: this.loadingStage.isError || Boolean(this._isModalButtonDisabled.value),
    };
  }

  open(params: OpenParams): void {
    this.detailLotModal.open();
    this._modalButtonText.change(params.buttonText ?? '');
    this._isModalButtonDisabled.change(params.isButtonDisabled ?? false);
    this._onClickValue.change(params.onClick ?? noop);

    if (params.type === LotDetailModalStoreOpenType.id) {
      this.fetchLot(params.value);
    } else {
      this.lot.change(params.value);
    }
  }

  close = (): void => {
    if (this.loadingStage.isLoading) {
      this._request.cancel();
    }

    this.detailLotModal.close();
  };

  onClick = () => {
    this._onClickValue.value?.();
  };

  async fetchLot(id: number): Promise<void> {
    if (this.loadingStage.isLoading) {
      this._request.cancel();
    }

    this.loadingStage.loading();

    const response = await this._request.call({
      url: apiUrls.selection.flat(id),
    });

    runInAction(() => {
      if (response.isError || !response.data) {
        this.loadingStage.error();

        this.lot.reset();

        return;
      }

      this.lot.change(CardLotModel.fromJson(response.data));
      this.loadingStage.success();
    });
  }
}
