import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import { ClientFixationPayload } from '@/entities/client/list';
import { CrmRejectionInfo, CrmRejectionInfoServer } from '@/entities/lead/list';
import { FixationFormDefaultValues, FixationFormModel } from '@/models/FixationFormModel';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { ToggleModel } from '@/models/ToggleModel';
import { ValueModel } from '@/models/ValueModel';
import { IRootStore } from '@/stores/global/RootStore';
import { ApiErrorCode, ApiErrorData, ApiErrorDataWithFields } from '@/types/meta';
import { Nullable } from '@/types/values';

import { LeadModel } from './LeadModel';
import { ManagerFormModel } from './ManagerFormModel';

export class LeadCreationModel {
  readonly fixationForm: FixationFormModel;
  readonly managerForm: ManagerFormModel;
  readonly submitStage = new LoadingStageModel();
  private readonly _isServerErrorModel = new ValueModel(false);
  private _submitRequest = apiStore.createRequest({
    method: 'POST',
    url: apiUrls.leads.create,
  });
  readonly errorModalState = new ToggleModel(false);
  readonly crmRejectionInfo = new ValueModel<Nullable<CrmRejectionInfo>>(null);
  readonly isNonunique = new ValueModel(false);

  constructor({ rootStore, defaultValues }: { rootStore: IRootStore; defaultValues?: FixationFormDefaultValues }) {
    this.fixationForm = new FixationFormModel({ rootStore, defaultValues });
    this.managerForm = new ManagerFormModel({ rootStore });
  }

  get isRequiredFieldsFilled(): boolean {
    return this.fixationForm.isRequiredFieldsFilled || this.managerForm.isRequiredFieldsFilled;
  }

  get isServerError(): boolean {
    return this._isServerErrorModel.value;
  }

  get isFieldError(): boolean {
    return this.fixationForm.isError || this.managerForm.isError;
  }

  submit = async (clientData: ClientFixationPayload): Promise<BaseResponse> => {
    const isValidationError = this._validate();

    if (isValidationError) {
      return {
        isError: true,
      };
    }

    return this._createLead(clientData);
  };

  resetServerError = (): void => {
    this._isServerErrorModel.change(false);
  };

  setInn = (inn: Nullable<string>): void => {
    this.fixationForm.inn.change(inn || '');
    this.fixationForm.inn.resetError();
    this.fixationForm.inn.setDisabled(Boolean(inn));
  };

  reset(): void {
    this.fixationForm.reset();
    this.managerForm.reset();
    this.errorModalState.close();
    this.resetServerError();
    this.submitStage.reset();
    this.isNonunique.change(false);
    this.crmRejectionInfo.change(null);
  }

  private _validate(): boolean {
    const isManagerFormError = this.managerForm.validate();
    const isFixationFormError = this.fixationForm.validate();

    if (isManagerFormError) {
      this.managerForm.scrollToErrorField();
      this.submitStage.error();

      return true;
    }

    if (isFixationFormError) {
      this.fixationForm.scrollToErrorField();
      this.submitStage.error();

      return true;
    }

    return false;
  }

  private async _createLead(clientJsonData: ClientFixationPayload): Promise<BaseResponse> {
    this.submitStage.loading();

    const fixationFormJsonData = this.fixationForm.toJson();
    const managerFormJsonData = this.managerForm.toJson();

    const response = await this._submitRequest.call({
      data: {
        ...clientJsonData,
        ...managerFormJsonData,
        ...fixationFormJsonData,
      },
    });

    if (!response.isError) {
      this.submitStage.success();

      return {
        isError: false,
      };
    }

    this.submitStage.error();

    return this._errorHandle(response.data?.data || null);
  }

  private _errorHandle(
    responseData: ApiErrorDataWithFields | ApiErrorData<ApiErrorCode.nonuniqueBid, CrmRejectionInfoServer> | null,
  ): BaseResponse {
    // Кейс, при котором сервер возвращает ошибку валидации полей формы
    if (
      (responseData?.code === ApiErrorCode.conflict || responseData?.code === ApiErrorCode.badRequest) &&
      responseData?.data?.fields
    ) {
      const fields = responseData.data.fields;
      Object.entries(fields).forEach(([fieldName, errorText]) => {
        switch (fieldName) {
          case 'project': {
            this.fixationForm.project.changeError(errorText || '');
            this.fixationForm.project.scrollToField();

            break;
          }

          case 'place_type': {
            this.fixationForm.placeType.changeError(errorText || '');
            this.fixationForm.placeType.scrollToField();

            break;
          }

          default: {
            this._isServerErrorModel.change(true);
          }
        }
      });

      this.errorModalState.open();

      return { isError: true };
    }

    // Кейс, когда созданная заявка не уникальна
    if (responseData?.code === ApiErrorCode.nonuniqueBid) {
      this.isNonunique.change(true);
      const crmRejectionInfo = responseData.data;

      if (!crmRejectionInfo) {
        return { isError: true };
      }

      this.crmRejectionInfo.change(LeadModel.normalizeCrmRejectionInfo(crmRejectionInfo));

      return {
        isError: true,
      };
    }

    this._isServerErrorModel.change(true);

    return { isError: true };
  }
}
