import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import Icon from '@/components/Icon';
import Typo, { TypoView, TypoWeight } from '@/components/Typo';
import { routerUrls } from '@/configs/routes';
import { useRootStore } from '@/stores/global/RootStore';

import Notifications from '../Notifications';

import s from './HeaderUserSection.module.scss';

const HeaderUserSection: React.FC = () => {
  const {
    userStore,
    modalsStore,
    uiStore: { isPhone },
  } = useRootStore();
  const navigate = useNavigate();
  const handleLogout = React.useCallback(async () => {
    const response = await userStore.logout();

    if (!response.isError) {
      navigate(routerUrls.root);
    }
  }, [userStore]);

  return (
    <div className={cn(s.section, modalsStore.menuModal.isOpen && s.section_overlayed)}>
      <Notifications className={s.section__notifications} />
      {!isPhone && (
        <Button className={s.section__name} element="button" view={ButtonType.secondary} size={ButtonSize.small}>
          <Typo view={TypoView.text} weight={TypoWeight.regular}>
            {userStore.user?.fullName || ''}
          </Typo>
        </Button>
      )}
      <Button
        className={s.section__exit}
        element="button"
        view={ButtonType.secondary}
        size={ButtonSize.small}
        onClick={handleLogout}
        after={<Icon iconName="IconExit" />}
        disabled={userStore.logoutStage.isLoading}
      >
        {!isPhone ? 'Выйти' : ''}
      </Button>
    </div>
  );
};

export default observer(HeaderUserSection);
