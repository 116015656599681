import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import Icon from '@/components/Icon';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { useRootStore } from '@/stores/global/RootStore';

import { TypoView } from '../Typo';

import s from './CopyButton.module.scss';

type CopyButtonProps = {
  className?: string;
  textToCopy: string;
  buttonText?: string;
  alertText: string;
};

const CopyButton: React.FC<CopyButtonProps> = ({ className, textToCopy, alertText, buttonText }) => {
  const { alertsStore } = useRootStore();
  const handleCopy = useCopyToClipboard(textToCopy, alertsStore, alertText);

  return (
    <Button
      view={ButtonType.secondary}
      textView={Boolean(buttonText) ? TypoView.buttonSmall : undefined}
      size={Boolean(buttonText) ? ButtonSize.small : undefined}
      className={cn(className, Boolean(buttonText) ? s.copy__btn : s['copy__btn_with-icon'])}
      onClick={handleCopy}
      after={!buttonText ? <Icon iconName="IconCopy" /> : undefined}
    >
      {buttonText}
    </Button>
  );
};

export default observer(CopyButton);
