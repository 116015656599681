import { IAlertsStore } from '@/stores/global/AlertsStore';

export const copyToClipboard = (text: string, alertsStore: IAlertsStore, alertText?: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alertsStore.addAlert({
        message: alertText ?? 'Ссылка скопирована',
      });
    })
    .catch(() =>
      alertsStore.addAlert({
        message: 'Произошла ошибка при копировании',
      }),
    );
};
