import cn from 'classnames';
import * as React from 'react';

import BaseClickable from '@/components/BaseClickable';
import Button, { ButtonProps, ButtonType } from '@/components/Button';
import Icon from '@/components/Icon';

import s from './BottomRightWindow.module.scss';

type RightWindowButtonButtonProps = ButtonProps<string> & { text: string };

export type BottomRightWindowProps = React.PropsWithChildren<{
  className?: string;
  childrenClassName?: string;
  title?: React.ReactNode;
  collapsedTitle?: React.ReactNode;
  isDefaultTitle?: React.ReactNode;
  closeIcon?: boolean;
  onClose?: VoidFunction;
  primaryButton: RightWindowButtonButtonProps;
  secondaryButton?: RightWindowButtonButtonProps;
  collapsed: boolean;
  toggleCollapse: VoidFunction;
}>;

const BottomRightWindow: React.FC<BottomRightWindowProps> = ({
  children,
  className,
  childrenClassName,
  title,
  collapsedTitle,
  isDefaultTitle = false,
  primaryButton,
  secondaryButton,
  closeIcon,
  collapsed,
  toggleCollapse,
  onClose,
}) => {
  const isClosedIcon = closeIcon && !collapsed;

  const iconName = isClosedIcon ? 'IconClose' : 'IconChevronLeft';

  const onIconClick = isClosedIcon ? onClose : toggleCollapse;

  return (
    <div className={cn(s.content, !collapsed && s.content_opened, className)}>
      {!isDefaultTitle && (
        <BaseClickable
          className={cn(s['content__icon-button'], !collapsed && s['content__icon-button_opened'])}
          onClick={onIconClick}
        >
          <Icon size={32} iconName={iconName} />
        </BaseClickable>
      )}

      {collapsed && collapsedTitle ? collapsedTitle : title}

      <div className={cn(s.content__children, !collapsed && s.content__children_opened, childrenClassName)}>
        {children}
      </div>
      <div className={Boolean(secondaryButton) ? s.content__buttons : s.content__button}>
        <Button
          {...primaryButton}
          view={ButtonType.primary}
          className={cn(
            Boolean(secondaryButton) ? s['content__buttons-item'] : s['content__button-item'],
            primaryButton.className,
          )}
        >
          {primaryButton.text}
        </Button>
        {secondaryButton && (
          <Button
            {...secondaryButton}
            view={ButtonType.secondary}
            className={cn(s['content__buttons-item'], secondaryButton.className)}
          >
            {secondaryButton.text}
          </Button>
        )}
      </div>
    </div>
  );
};

export default BottomRightWindow;
