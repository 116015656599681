export enum ManagerFormFields {
  managerId = 'managerId',
  isSubAgent = 'isSubAgent',
  subAgentFullName = 'subAgentFullName',
  subAgentPhone = 'subAgentPhone',
}

export const WITH_SUBAGENT_FIELDS: ManagerFormFields[] = [
  ManagerFormFields.subAgentFullName,
  ManagerFormFields.subAgentPhone,
];

export const WITHOUT_SUBAGENT_FIELDS: ManagerFormFields[] = [ManagerFormFields.managerId];
