import { useEffect } from 'react';

export enum KeyboardKey {
  enter = 'enter',
  escape = 'escape',
  left = 'left',
  right = 'right',
}

type KeyboardKeyChecker = (e: KeyboardEvent) => boolean;

const mapKeyboardKeyToChecker: Record<KeyboardKey, KeyboardKeyChecker> = {
  [KeyboardKey.enter]: (e) => e.key === 'Enter',
  [KeyboardKey.escape]: (e) => e.key === 'Escape',
  [KeyboardKey.left]: (e) => e.key === 'ArrowLeft',
  [KeyboardKey.right]: (e) => e.key === 'ArrowRight',
};

type Props = {
  func: VoidFunction;
  checks?: boolean[];
  key?: KeyboardKey;
};

export const useKeyDown = ({ func, checks = [], key = KeyboardKey.enter }: Props) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (mapKeyboardKeyToChecker[key](e)) {
        func();
      }
    };

    if (!checks.length || checks.some((check) => check)) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [func, ...checks]);
};
