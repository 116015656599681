import cn from 'classnames';
import * as React from 'react';

import { getColor } from '@/utils/getColor';

import { iconMap } from './config';
import { IconProps } from './types';

import s from './Icon.module.scss';

const Icon: React.FC<IconProps> = ({ className, size, width, height, color, iconName, iconRotation, ...props }) => {
  const iconWidth = width || size;
  const iconHeight = height || size;

  const Component = iconMap[iconName];

  return (
    <Component
      className={cn(className, s.icon)}
      style={{
        width: iconWidth,
        height: iconHeight,
        transform: iconRotation ? `rotate(${iconRotation}deg)` : undefined,
      }}
      role="img"
      color={color ? getColor(color) : undefined}
      {...props}
    />
  );
};

export default Icon;
