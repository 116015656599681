import cn from 'classnames';
import * as React from 'react';

import Typo, { TypoElement, TypoView, TypoWeight } from '@/components/Typo';

import s from './LotTag.module.scss';

type LotTagProps = {
  className?: string;
  tag: string;
  tagView?: TypoView;
  tagWeight?: TypoWeight;
};

const LotTag: React.FC<LotTagProps> = ({
  className,
  tag,
  tagView = TypoView.caption,
  tagWeight = TypoWeight.regular,
}) => {
  return (
    <Typo element={TypoElement.p} view={tagView} weight={tagWeight} className={cn(s.tag, className)}>
      {tag}
    </Typo>
  );
};

export default React.memo(LotTag);
