import { computed, makeObservable } from 'mobx';

import { noop } from '@kts-front/utils';

import { RightWindowProps } from '@/components/Modal/RightWindow';
import { CreateClientResultType, CreateLeadResultType } from '@/entities/modal';
import { LeadCreationModel } from '@/models/LeadCreationModel';
import { ToggleModel } from '@/models/ToggleModel';
import { ValueModel } from '@/models/ValueModel';
import { Nullable } from '@/types/values';

import { IRootStore } from '../RootStore';

import ClientFixationStore from './ClientFixationStore';
import { FixationCreationMode, FixationCreationStage } from './config';
import { ClientResultModalState, IFixationModalStore, OpenParams } from './types';

type Params = {
  rootStore: IRootStore;
};

export default class FixationModalStore implements IFixationModalStore {
  private readonly _stageModel = new ValueModel<FixationCreationStage>(FixationCreationStage.clientSearch);
  private readonly _modeModel = new ValueModel<FixationCreationMode>(FixationCreationMode.full);
  readonly clientFixationStore = new ClientFixationStore();
  readonly modalState = new ToggleModel();
  readonly leadCreationStore: LeadCreationModel;
  readonly resultState = new ValueModel<Nullable<ClientResultModalState>>(null);

  readonly refetchFunction = new ValueModel<VoidFunction>(noop);

  constructor({ rootStore }: Params) {
    this.leadCreationStore = new LeadCreationModel({ rootStore });

    makeObservable(this, {
      buttons: computed,
      isClientNotFound: computed,
    });
  }

  get stage(): FixationCreationStage {
    return this._stageModel.value;
  }

  get mode(): FixationCreationMode {
    return this._modeModel.value;
  }

  get buttons(): Pick<RightWindowProps, 'primaryButton' | 'secondaryButton'> {
    if (this.stage === FixationCreationStage.clientForm) {
      const {
        clientCreation: {
          isServerError,
          isFieldError,
          isRequiredFieldsFilled,
          submitStage: { isLoading },
        },
      } = this.clientFixationStore;

      return {
        primaryButton: {
          text: 'Добавить клиента без фиксации',
          onClick: this._createClient,
          disabled: isFieldError || isServerError || !isRequiredFieldsFilled,
          isLoading: isLoading,
        },
        secondaryButton: {
          text: 'Зафиксировать клиента',
          onClick: this.goToLeadCreation,
          disabled: isFieldError || !isRequiredFieldsFilled,
        },
      };
    }

    if (this.stage === FixationCreationStage.leadForm) {
      return {
        primaryButton: {
          text: 'Зафиксировать клиента',
          onClick: this._createLead,
          disabled: this.leadCreationStore.isFieldError || this.leadCreationStore.isServerError,
          isLoading: this.leadCreationStore.submitStage.isLoading,
        },
      };
    }

    if (this.stage === FixationCreationStage.result) {
      return {
        primaryButton: {
          text: this.resultState.value?.type === CreateClientResultType.successClient ? 'Добавить еще' : 'Создать еще',
          onClick: this._restart,
        },
      };
    }

    return {};
  }

  get isServerError(): boolean {
    return this.clientFixationStore.clientCreation.isServerError || this.leadCreationStore.isServerError;
  }

  resetServerError = (): void => {
    this.clientFixationStore.clientCreation.resetServerError();
    this.leadCreationStore.resetServerError();
  };

  get isClientNotFound(): boolean {
    return (
      Boolean(this.clientFixationStore.clientPhone.value) &&
      this.clientFixationStore.searchOptions.length === 0 &&
      this.clientFixationStore.clientList.list.loadingStage.isFinished
    );
  }

  open = (params: OpenParams): void => {
    this.modalState.open();
    this._modeModel.change(params.mode);

    if (params.mode === FixationCreationMode.full) {
      this._stageModel.change(FixationCreationStage.clientSearch);

      return;
    }

    this.clientFixationStore.changeClient(params.client);
    this.leadCreationStore.setInn(params.client?.inn || null);
    this._stageModel.change(FixationCreationStage.leadForm);
  };

  close = (): void => {
    this.modalState.close();
    this._reset();
  };

  goToClientCreation = (): void => {
    this._stageModel.change(FixationCreationStage.clientForm);
    this.clientFixationStore.resetForm();
    this.clientFixationStore.setPhoneField(this.clientFixationStore.clientPhone.value);
    this.selectClient(null);
  };

  goToLeadCreation = (): void => {
    const isClientFormError = this.clientFixationStore.clientCreation.validate();

    if (isClientFormError) {
      return;
    }

    this._stageModel.change(FixationCreationStage.leadForm);
  };

  goBackToClient = (): void => {
    this._stageModel.change(FixationCreationStage.clientForm);
    this.leadCreationStore.reset();
  };

  selectClient = (id: Nullable<string>): void => {
    const client = this.clientFixationStore.getClientById(id);
    this.clientFixationStore.changeClient(client);
    this.leadCreationStore.setInn(client?.inn || null);

    if (client) {
      this._stageModel.change(FixationCreationStage.clientForm);
    }
  };

  private _restart = (): void => {
    if (this.mode === FixationCreationMode.skipClient) {
      this._stageModel.change(FixationCreationStage.leadForm);
      this._baseReset();
      this.leadCreationStore.setInn(this.clientFixationStore.selectedClient.value?.inn || null);

      return;
    }

    this._stageModel.change(FixationCreationStage.clientSearch);
    this._reset();
  };

  private _createLead = async (): Promise<void> => {
    const clientData = this.clientFixationStore.getClientJson();

    const response = await this.leadCreationStore.submit(clientData);

    if (!response.isError) {
      this.resultState.change({ type: CreateLeadResultType.success });
      this._stageModel.change(FixationCreationStage.result);
      this._refetch();

      return;
    }

    if (!this.leadCreationStore.isNonunique.value) {
      return;
    }

    const crmRejectionInfo = this.leadCreationStore.crmRejectionInfo.value;

    this.resultState.change({ type: CreateLeadResultType.refused, crmRejectionInfo });
    this._stageModel.change(FixationCreationStage.result);
    this._refetch();
  };

  private _reset = (): void => {
    this.clientFixationStore.reset();
    this._stageModel.reset();
    this._baseReset();
  };

  private _baseReset = (): void => {
    this.leadCreationStore.reset();
    this.resultState.reset();
  };

  private _createClient = async (): Promise<void> => {
    const response = await this.clientFixationStore.fixateClient();

    if (response.isError) {
      return;
    }

    this.resultState.change({ type: CreateClientResultType.successClient });
    this._stageModel.change(FixationCreationStage.result);

    this._refetch();
  };

  private _refetch(): void {
    try {
      this.refetchFunction.value();
    } catch (error) {
      console.error(error);
    }
  }
}
