import { Nullable } from '@/types/values';

import { ProjectServer } from '../../dictionaries';

export type SubAgent = Nullable<{
  name: string;
  phone: string;
}>;

export type ManagerServer = {
  id: number;
  first_name: string;
  last_name?: string;
};

export enum LeadStatus {
  unique = 'unique',
  wasUnique = 'wasunique',
  nonUnique = 'nonunique',
}

export const leadStatusNames: Record<LeadStatus, string> = {
  [LeadStatus.unique]: 'Уникален',
  [LeadStatus.wasUnique]: 'Уникальность завершена',
  [LeadStatus.nonUnique]: 'Неуникален',
};

export type BaseLeadServer = {
  id: number;
  status: LeadStatus;
  place_type: string;
  created_at: string;
  hold_date?: string;
  project: Pick<ProjectServer, 'id' | 'name'>;
  manager: ManagerServer;
  sub_agent: SubAgent;
  comment: string;
};
