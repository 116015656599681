import cn from 'classnames';
import * as React from 'react';

import { ValueProp } from '@/components/BaseClickable';
import Button, { ButtonProps, ButtonType } from '@/components/Button';
import IconButton from '@/components/IconButton';
import Typo, { TypoElement, TypoView } from '@/components/Typo';

import s from './Confirm.module.scss';

export enum ConfirmCloseButtonPosition {
  within = 'within',
  out = 'out',
}

type ModalButtonProps = Pick<ButtonProps<ValueProp>, 'onClick' | 'disabled'> & { text: string };

export type ConfirmProps = {
  className?: string;
  contentClassName?: string;
  buttonClassName?: string;
  primaryButtonProps?: ModalButtonProps;
  secondaryButtonProps?: ModalButtonProps;
  showCloseButton?: boolean;
  onClose: VoidFunction;
  title?: React.ReactNode;
  children?: React.ReactNode;
  closeButtonPosition?: ConfirmCloseButtonPosition;
};

const Confirm: React.FC<ConfirmProps> = ({
  title,
  children,
  showCloseButton = true,
  onClose,
  className,
  contentClassName,
  buttonClassName,
  primaryButtonProps,
  secondaryButtonProps,
  closeButtonPosition = ConfirmCloseButtonPosition.within,
}) => {
  const showButtons = primaryButtonProps?.text || secondaryButtonProps?.text;

  return (
    <div className={cn(s.content, className)}>
      {title && (
        <Typo
          className={cn(
            showCloseButton && s['content__title_with-close-btn'],
            s.content__title,
            !children && s['content__title_large-indent'],
          )}
          view={TypoView.h5}
          element={TypoElement.h5}
        >
          {title}
        </Typo>
      )}
      {children && (
        <Typo
          className={cn(showButtons && s['content__content_with-buttons'], contentClassName)}
          view={TypoView.text}
          element={TypoElement.h5}
        >
          {children}
        </Typo>
      )}
      {showButtons && (
        <div className={s.content_buttons}>
          {primaryButtonProps && (
            <Button
              className={buttonClassName}
              view={ButtonType.primary}
              element="button"
              onClick={primaryButtonProps.onClick}
              disabled={primaryButtonProps.disabled}
            >
              {primaryButtonProps.text}
            </Button>
          )}
          {secondaryButtonProps && (
            <Button
              className={buttonClassName}
              view={ButtonType.secondary}
              element="button"
              onClick={secondaryButtonProps.onClick}
              disabled={secondaryButtonProps.disabled}
            >
              {secondaryButtonProps.text}
            </Button>
          )}
        </div>
      )}
      {showCloseButton && (
        <IconButton
          className={cn(s.content__closeIcon, s[`content__closeIcon_${closeButtonPosition}`])}
          iconName="IconClose"
          onClick={onClose}
        />
      )}
    </div>
  );
};

export default Confirm;
