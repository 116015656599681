import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonType } from '@/components/Button';
import Typo, { TypoElement, TypoView } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';

import VideoCarousel from './VideoCarousel';

import s from './Content.module.scss';

const Content: React.FC = () => {
  const {
    uiStore: { isLessThanDesktop },
    modalsStore: {
      PWATutorialModalStore: { close, mobileVideoSource },
    },
  } = useRootStore();

  return (
    <div className={s.content}>
      <div className={s.content__info}>
        <div className={s['content__info-text']}>
          <Typo
            view={isLessThanDesktop ? TypoView.h5 : TypoView.h3}
            element={isLessThanDesktop ? TypoElement.h5 : TypoElement.h3}
          >
            Добавьте ЛК партнера к себе как приложение
          </Typo>
          <Typo view={TypoView.text} element={TypoElement.p}>
            и пользуйтесь как мобильным приложением
          </Typo>
        </div>
        <Button className={s['content__info-btn']} view={ButtonType.primary} onClick={close}>
          Хорошо
        </Button>
      </div>
      {isLessThanDesktop ? (
        <div className={s['content__video-wrapper']}>
          <video src={mobileVideoSource} autoPlay muted loop playsInline className={s.content__video} />
        </div>
      ) : (
        <VideoCarousel />
      )}
    </div>
  );
};

export default observer(Content);
