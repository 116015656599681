import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useToggleState } from '@kts-front/hooks';

import Modal, { ModalVariant } from '@/components/Modal';
import Typo, { TypoView, TypoWeight } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';

import Icon from '../Icon';

import s from './NotificationsSubscribeModal.module.scss';

const NotificationsSubscribeModal: React.FC = () => {
  const { pushSubscribeStore } = useRootStore();

  const { toggle, opened } = useToggleState();

  return (
    <Modal
      className={s.modal}
      childrenClassName={s.modal__content}
      variant={ModalVariant.BottomRight}
      opened={pushSubscribeStore.modal.isOpen}
      onClose={pushSubscribeStore.modal.close}
      ariaLabel="Чтобы получать уведомления по клиентам - подпишитесь"
      collapsedTitle={false}
      isDefaultTitle
      primaryButton={{
        text: 'Подписаться',
        onClick: pushSubscribeStore.trySubscribe,
        className: s.modal__button,
        textView: TypoView.buttonSmall,
      }}
      secondaryButton={{
        text: 'Позже',
        onClick: pushSubscribeStore.closeModal.bind(pushSubscribeStore),
        className: s.modal__button,
        textView: TypoView.buttonSmall,
      }}
      title={
        <div className={s.modal__title}>
          <Icon iconName="IconNotificationsSubscribe" />
          <Typo view={TypoView.textMedium} weight={TypoWeight.medium}>
            Чтобы получать уведомления по клиентам - подпишитесь
          </Typo>
        </div>
      }
      toggleCollapse={toggle}
      collapsed={!opened}
    />
  );
};

export default observer(NotificationsSubscribeModal);
