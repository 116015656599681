import IconAccount from './icons/IconAccount.svg';
import IconAccounts from './icons/IconAccounts.svg';
import IconArrowBack from './icons/IconArrowBack.svg';
import IconArrowDropDown from './icons/IconArrowDropDown.svg';
import IconArrowLeft from './icons/IconArrowLeft.svg';
import IconArrowUp from './icons/IconArrowUp.svg';
import IconBlock from './icons/IconBlock.svg';
import IconBucket from './icons/IconBucket.svg';
import IconBurger from './icons/IconBurger.svg';
import IconBusiness from './icons/IconBusiness.svg';
import IconCheck from './icons/IconCheck.svg';
import IconChevronLeft from './icons/IconChevronLeft.svg';
import IconClose from './icons/IconClose.svg';
import IconCommercial from './icons/IconCommercial.svg';
import IconCopy from './icons/IconCopy.svg';
import IconDoubleArrowLeft from './icons/IconDoubleArrowLeft.svg';
import IconEdit from './icons/IconEdit.svg';
import IconExit from './icons/IconExit.svg';
import IconFavorite from './icons/IconFavorite.svg';
import IconFavoriteOutline from './icons/IconFavoriteOutline.svg';
import IconFilter from './icons/IconFilter.svg';
import IconGridOutline from './icons/IconGridOutline.svg';
import IconImportantNotification from './icons/IconImportantNotification.svg';
import IconInterestBidCreated from './icons/IconInterestBidCreated.svg';
import IconLinkArrow from './icons/IconLinkArrow.svg';
import IconListOutline from './icons/IconListOutline.svg';
import IconLiving from './icons/IconLiving.svg';
import IconLoading from './icons/IconLoading.svg';
import IconLogin from './icons/IconLogin.svg';
import IconNotification from './icons/IconNotification.svg';
import IconNotificationsSubscribe from './icons/IconNotificationsSubscribe.svg';
import IconParking from './icons/IconParking.svg';
import IconPlus from './icons/IconPlus.svg';
import IconRefusal from './icons/IconRefusal.svg';
import IconRegistrationSuccess from './icons/IconRegistrationSuccess.svg';
import IconSearch from './icons/IconSearch.svg';
import IconShare from './icons/IconShare.svg';
import IconSignup from './icons/IconSignup.svg';
import IconSort from './icons/IconSort.svg';
import IconStorage from './icons/IconStorage.svg';
import IconTimelapse from './icons/IconTimelapse.svg';
import IconUnblock from './icons/IconUnblock.svg';

export const iconMap = {
  IconArrowBack,
  IconArrowDropDown,
  IconArrowLeft,
  IconArrowUp,
  IconBlock,
  IconBucket,
  IconBurger,
  IconBusiness,
  IconCheck,
  IconChevronLeft,
  IconClose,
  IconCommercial,
  IconCopy,
  IconDoubleArrowLeft,
  IconExit,
  IconFilter,
  IconImportantNotification,
  IconInterestBidCreated,
  IconLiving,
  IconLoading,
  IconLogin,
  IconNotification,
  IconNotificationsSubscribe,
  IconParking,
  IconPlus,
  IconRefusal,
  IconRegistrationSuccess,
  IconSearch,
  IconSignup,
  IconStorage,
  IconTimelapse,
  IconUnblock,
  IconEdit,
  IconFavoriteOutline,
  IconFavorite,
  IconSort,
  IconLinkArrow,
  IconAccount,
  IconShare,
  IconAccounts,
  IconGridOutline,
  IconListOutline,
};
