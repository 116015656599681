import { LeadStatus } from '@/entities/lead/base';
import { CrmRejectionInfo, CrmRejectionInfoServer, ILead, LeadServer } from '@/entities/lead/list';
import { BaseLeadModel } from '@/models/BaseLeadModel';
import { getFullName } from '@/utils/getFullName';

import { BaseClientModel } from './BaseClientModel';

export class LeadModel extends BaseLeadModel implements ILead {
  readonly crmRejectionInfo;
  readonly client;

  constructor({
    id,
    comment,
    createdDate,
    expiryDate,
    managerId,
    managerFullName,
    placeType,
    project,
    status,
    subAgent,
    crmRejectionInfo,
    client,
  }: ILead) {
    super({ managerId, managerFullName, id, status, placeType, createdDate, expiryDate, project, comment, subAgent });
    this.crmRejectionInfo = crmRejectionInfo;
    this.client = client;
  }

  get clientFullName() {
    return this.client.clientFullName;
  }

  get clientPhone() {
    return this.client.phone;
  }

  static fromJson(raw: LeadServer): LeadModel {
    const subAgent =
      raw.sub_agent?.name && raw.sub_agent?.phone
        ? {
            name: raw.sub_agent.name,
            phone: raw.sub_agent.phone,
          }
        : null;

    const crmRejectionInfo =
      raw.status === LeadStatus.nonUnique && raw.crm_rejection_info
        ? this.normalizeCrmRejectionInfo(raw.crm_rejection_info)
        : null;

    const client = BaseClientModel.fromJson(raw.client);

    return new LeadModel({
      id: raw.id,
      comment: raw.comment,
      createdDate: raw.created_at,
      expiryDate: raw.hold_date,
      managerId: raw.manager.id,
      managerFullName: getFullName({
        firstName: raw.manager.first_name,
        lastName: raw.manager.last_name,
      }),
      placeType: raw.place_type,
      project: raw.project.name,
      status: raw.status,
      crmRejectionInfo,
      subAgent,
      client,
    });
  }

  static normalizeCrmRejectionInfo(from: CrmRejectionInfoServer): CrmRejectionInfo {
    return {
      leadId: from.lead_id,
      manager: {
        name: from.manager?.name || null,
        phone: from.manager?.phone || null,
      },
      agency: {
        title: from.agency?.title || null,
      },
      message: from.message || null,
      holdDate: from.hold_date || null,
    };
  }
}
