import * as React from 'react';
import { RouterProvider } from 'react-router-dom';

import router from '@/configs/routes/router';
import { RootStoreProvider } from '@/stores/global/RootStore';

const Root = (): React.ReactElement => {
  return (
    <RootStoreProvider>
      <RouterProvider router={router} />
    </RootStoreProvider>
  );
};

export default Root;
