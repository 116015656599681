import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import Popup from 'reactjs-popup';

import { useToggleState } from '@kts-front/hooks';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import Icon from '@/components/Icon';
import { TypoView } from '@/components/Typo';

import { NavItem } from '../../config';
import { NavItemProps } from '../../types';

import NavItemAdditional from './NavItemAdditional';

import s from './NavigationDropdown.module.scss';

type NavigationDropdownProps = {
  items: NavItem[];
  linksConfig: Record<NavItem, NavItemProps>;
};

const NavigationDropdown: React.FC<NavigationDropdownProps> = ({ items, linksConfig }) => {
  const { opened, open, close } = useToggleState();

  return (
    <div className={s.dropdown}>
      <Popup
        trigger={
          <Button
            view={ButtonType.ghost}
            textView={TypoView.text}
            className={s.dropdown__title}
            size={ButtonSize.custom}
            after={<Icon iconName="IconChevronLeft" size={20} iconRotation={-90} />}
          >
            Дополнительно
          </Button>
        }
        arrow={false}
        offsetY={14.5}
        onClose={close}
        onOpen={open}
        open={opened}
        position={'bottom left'}
        nested
      >
        <AnimatePresence>
          {opened && (
            <motion.div
              className={s.dropdown__list}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              {items.map((link) => (
                <NavItemAdditional key={link} {...linksConfig[link]} onClick={close} />
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </Popup>
    </div>
  );
};

export default React.memo(NavigationDropdown);
