import { computed, makeObservable } from 'mobx';

import { BaseResponse } from '@kts-front/types';
import { debounce } from '@kts-front/utils';

import { apiUrls } from '@/api';
import { BaseClientInfo, BaseClientServer, IBaseClient } from '@/entities/client/base';
import { BaseClientListResponse, ClientFixationPayload } from '@/entities/client/list';
import { BaseClientModel } from '@/models/BaseClientModel';
import { ClientCreationModel } from '@/models/ClientCreationModel';
import { InfiniteListModel } from '@/models/InfiniteListModel';
import { InputModel } from '@/models/InputModel';
import { PhoneNumber } from '@/models/PhoneNumber';
import { ValueModel } from '@/models/ValueModel';
import { Nullable } from '@/types/values';

import { ClientSearchOption, IClientFixationStore } from './types';

const CLIENTS_LIMIT = 20;
const DEBOUNCED_UPDATE_TIMEOUT = 600;

export default class ClientFixationStore implements IClientFixationStore {
  readonly clientCreation: ClientCreationModel;

  readonly clientPhone = new InputModel<string>({
    name: 'phone',
    caption: 'Введите номер телефона',
    initialValue: '',
  });

  readonly clientList = new InfiniteListModel<BaseClientModel, BaseClientServer, BaseClientListResponse>({
    limit: CLIENTS_LIMIT,
    normalizer: (rawItem: BaseClientServer) => ({
      entity: BaseClientModel.fromJson(rawItem),
      key: `${rawItem.id}`,
    }),
    getEntities: (response) => ({
      entities: response.clients,
      total: response.total,
    }),
    requestParams: {
      method: 'POST',
      url: apiUrls.client.simpleList,
    },
    loaderDelay: 500,
  });
  readonly selectedClient = new ValueModel<IBaseClient | null>(null);

  constructor() {
    this.clientCreation = new ClientCreationModel({});

    makeObservable(this, {
      searchOptions: computed,
      clientInfo: computed,
    });
  }

  get searchOptions(): ClientSearchOption[] {
    return this.clientList.list.items.map((item) => ({
      id: item.id,
      label: item.phone,
      client: item,
    }));
  }

  get clientInfo(): BaseClientInfo {
    return {
      firstName: this.clientCreation.clientForm.clientFirstName.value,
      lastName: this.clientCreation.clientForm.clientLastName.value,
      phone: this.clientCreation.clientForm.clientPhone.value,
      email: this.clientCreation.clientForm.clientEmail.value,
      patronymic: this.clientCreation.clientForm.clientPatronymic.value,
    };
  }

  setPhoneField(value: string): void {
    this.clientCreation.clientForm.clientPhone.change(value);
  }

  loadClients = async ({ reset = true }: { reset?: boolean }): Promise<BaseResponse> => {
    const lastClient = reset ? null : this.clientList.list.items.at(-1);

    return this.clientList.load({
      reset,
      data: {
        pagination: {
          limit: CLIENTS_LIMIT,
          last_id: lastClient ? lastClient.clientId : null,
        },
        phone: this.clientPhone.value ? PhoneNumber.removeCountryCode(this.clientPhone.value) : '',
      },
    });
  };

  loadMoreClients = () => this.loadClients({ reset: false });

  debouncedLoadClients = debounce({ func: this.loadClients, timeout: DEBOUNCED_UPDATE_TIMEOUT });

  changeSearch = (value: string): void => {
    if (this.clientList.list.loadingStage.isLoading) {
      this.clientList.cancelRequest();
    }

    this.clientPhone.change(value);
  };

  reset(): void {
    this.resetForm();

    if (this.clientPhone.value) {
      this.clientList.reset();
    }

    this.clientPhone.reset();
  }

  resetForm = (): void => {
    this.clientCreation.reset();
    this.clientCreation.clientForm.setDisableFields(false);
    this.selectedClient.reset();
  };

  fixateClient = (): Promise<BaseResponse> => {
    return this.selectedClient.value == null
      ? this.clientCreation.submit()
      : this.clientCreation.createClient(this.selectedClient.value.clientId);
  };

  changeClient = (client: IBaseClient | null): void => {
    this.selectedClient.change(client);

    if (client) {
      this.clientCreation.clientForm.setDisableFields(true);
      this.clientCreation.clientForm.updateFields(client);
    }
  };

  getClientById = (id: Nullable<string>): IBaseClient | null => {
    return id ? this.clientList.list.getEntity(id) : null;
  };

  getClientJson = (): ClientFixationPayload => {
    if (this.selectedClient.value) {
      return {
        client_id: this.selectedClient.value.clientId,
      };
    }

    return { client: this.clientCreation.clientForm.toJson() };
  };
}
