import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import { LeadStatus } from '@/entities/lead/base';
import { ILead } from '@/entities/lead/list';
import { useRootStore } from '@/stores/global/RootStore';

import s from './LeadActions.module.scss';

type Props = {
  item: ILead;
  className?: string;
  buttonClassName?: string;
  buttonView?: ButtonType;
  buttonSize?: ButtonSize;
  showShortText?: boolean;
  refetch?: VoidFunction;
  openCreateLead: VoidFunction;
};

const LeadActions: React.FC<Props> = ({
  item,
  className,
  buttonView = ButtonType.primary,
  buttonSize = ButtonSize.xs,
  buttonClassName,
  showShortText = false,
  openCreateLead,
}) => {
  const {
    modalsStore: { meetingNoticeModal },
  } = useRootStore();

  const buttonProps = React.useMemo(
    () => ({
      className: cn(s.actions_button, buttonClassName),
      size: buttonSize,
      view: buttonView,
    }),
    [buttonClassName, buttonSize, buttonView],
  );

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <div className={cn(s.actions, className)} onClick={handleClick}>
        {item.status === LeadStatus.unique && (
          <>
            <Button {...buttonProps} element="button" onClick={meetingNoticeModal.open}>
              {showShortText ? 'Новая встреча' : 'Назначить встречу'}
            </Button>
          </>
        )}
        {item.status === LeadStatus.wasUnique && (
          <Button {...buttonProps} element="button" onClick={openCreateLead}>
            {showShortText ? 'Новая заявка' : 'Создать заявку'}
          </Button>
        )}
      </div>
    </>
  );
};

export default observer(LeadActions);
