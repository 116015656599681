import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { DoubleField } from '@/components/form/DoubleField';
import InputField from '@/components/form/InputField';
import PhoneField from '@/components/form/PhoneField';
import { IClientForm } from '@/entities/client/list';

import s from './ClientFormFields.module.scss';

type ClientFormFieldsProps = {
  formModel: IClientForm;
  className?: string;
};

const ClientFormFields = ({
  formModel,
  className,
}: ClientFormFieldsProps): React.ReactElement<ClientFormFieldsProps> => {
  const { clientEmail, clientFirstName, clientLastName, clientPhone, clientPatronymic } = formModel;

  return (
    <div className={cn(s['client-form-fields'], className)}>
      <PhoneField {...clientPhone.props} />
      <DoubleField>
        <InputField {...clientLastName.props} />
        <InputField {...clientFirstName.props} />
      </DoubleField>
      <InputField {...clientPatronymic.props} />
      <InputField {...clientEmail.props} />
    </div>
  );
};

export default observer(ClientFormFields);
