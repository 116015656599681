import { differenceInMonths } from 'date-fns';

import AndroidPWATutorial from '@/assets/pwa-tutorial/AndroidPWATutorial.mp4';
import IOSPWATutorial from '@/assets/pwa-tutorial/IOSPWATutorial.mp4';
import { ownLocalStorage, StorageKeys } from '@/models/LocaleStorageModel';
import { ToggleModel } from '@/models/ToggleModel';
import { checkIsIOS } from '@/utils/checkIsIOS';
import { checkIsPWA } from '@/utils/checkIsPWA';

import { IPWATutorialModalStore } from './types';

export class PWATutorialModalStore implements IPWATutorialModalStore {
  readonly PWATutorialModal: ToggleModel = new ToggleModel();

  get isPWAModalAvailable(): boolean {
    if (checkIsPWA()) {
      return false;
    }

    const closeTime = ownLocalStorage.getItem(StorageKeys.PWATutorialClosedTime);

    if (!closeTime) {
      this.PWATutorialModal.open();

      return true;
    }

    const monthsPassed = differenceInMonths(new Date(), new Date(Number(closeTime)));

    if (monthsPassed >= 1) {
      this.PWATutorialModal.open();

      return true;
    }

    return false;
  }

  get mobileVideoSource(): string {
    if (checkIsIOS()) {
      return IOSPWATutorial;
    }

    return AndroidPWATutorial;
  }

  close = (): void => {
    this._rememberCloseTime();
    this.PWATutorialModal.close();
  };

  private _rememberCloseTime = (): void => {
    const now = new Date().getTime();
    ownLocalStorage.setItem(StorageKeys.PWATutorialClosedTime, now.toString());
  };
}
