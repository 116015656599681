import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonType } from '@/components/Button';
import Typo, { TypoAlign, TypoElement, TypoView } from '@/components/Typo';
import { useUiStore } from '@/stores/global/RootStore';

import s from './Content.module.scss';

type ContentProps = {
  onSubmitClick: VoidFunction;
};

const Content: React.FC<ContentProps> = ({ onSubmitClick }) => {
  const { isLessThanDesktop } = useUiStore();

  return (
    <div className={s.content}>
      <div className={s.content__header}>
        <Typo
          view={isLessThanDesktop ? TypoView.h4 : TypoView.h3}
          element={isLessThanDesktop ? TypoElement.h4 : TypoElement.h3}
          textAlign={TypoAlign.center}
        >
          Уважаемые партнеры!
        </Typo>
        <Typo
          view={isLessThanDesktop ? TypoView.h6 : TypoView.h4}
          element={isLessThanDesktop ? TypoElement.h6 : TypoElement.h4}
          textAlign={TypoAlign.center}
          className={s['content__header-subinfo']}
        >
          Сообщаем об изменениях в реализации проекта Republic.
        </Typo>
      </div>
      <div className={s.content__info}>
        <Typo view={isLessThanDesktop ? TypoView.text : TypoView.h6} className={s['content__info-text']}>
          В целях оптимизации и повышения эффективности операционных процессов между компаниями-партнерами FORMA и
          «Страна Девелопмент», управление продажами по проекту Republic с 1 апреля переходит «Страна Девелопмент».
        </Typo>
        <Typo view={isLessThanDesktop ? TypoView.text : TypoView.h6} className={s['content__info-text']}>
          Все фиксации клиентов по Republic теперь необходимо проводить напрямую в личном кабинете «Страна Девелопмент».
        </Typo>
      </div>
      <Button view={ButtonType.primary} onClick={onSubmitClick} className={s.content__actions}>
        Ознакомлен
      </Button>
    </div>
  );
};

export default observer(Content);
