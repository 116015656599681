import { CardLotServer } from '@/entities/lots';
import { ICardLot } from '@/entities/lots/client';
import { BulkModel } from '@/models/BulkModel';
import { PlaceTypeModel } from '@/models/PlaceTypeModel';
import { ToggleModel } from '@/models/ToggleModel';
import { capitalizeFirstLetter } from '@/utils/capitalizeFistLetter';

import { BaseLotModel } from '../BaseLotModel';

export class CardLotModel extends BaseLotModel implements ICardLot {
  readonly isFavorite;
  readonly image;
  readonly attributes;

  constructor({ isFavorite, image, attributes, ...rest }: ICardLot) {
    super(rest);

    this.isFavorite = isFavorite;
    this.image = image;
    this.attributes = attributes;
  }

  get hasPriceDiff(): boolean {
    return Boolean(this.realPrice) && this.realPrice !== this.currentPrice;
  }

  static fromJsonToObj(raw: CardLotServer): ICardLot {
    return {
      id: raw.id,
      area: raw.area,
      floor: raw.floor,
      realPrice: raw.real_price,
      currentPrice: raw.current_price ?? raw.real_price,
      rooms: raw.rooms === '0' ? '' : raw.rooms === 'studio' ? 'Студия' : `${String(raw.rooms)}-к кв.`,
      bulk: BulkModel.fromJson(raw.bulk),
      flatNumber: raw.number,
      sectionNumber: raw.section.number,
      placeType: PlaceTypeModel.fromJson(raw.place_type),
      isFavorite: new ToggleModel(false),
      image: raw.flat_plan_png,
      status: raw.status,
      attributes: raw.attributes ? raw.attributes.map((item) => capitalizeFirstLetter(item.name)) : [],
    };
  }

  static fromJson(raw: CardLotServer): CardLotModel {
    return new CardLotModel(CardLotModel.fromJsonToObj(raw));
  }
}
