import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { FixationForm } from '@/components/FixationForm';
import ManagerForm from '@/components/ManagerForm';
import { withDictionaries } from '@/hocs/withDictionaries';
import { DictionaryFields } from '@/stores/global/DictionariesStore';
import { useFixationModalStore } from '@/stores/global/RootStore';

import ClientInfo from './ClientInfo';

import s from './LeadFixation.module.scss';

const LeadFixation: React.FC = () => {
  const {
    leadCreationStore: { managerForm, fixationForm },
  } = useFixationModalStore();

  // для того, чтобы установить дефолтные значения после загрузки словарей
  React.useEffect(() => {
    if (!fixationForm.project.value) {
      fixationForm.setDefaultValues();
    }
  }, []);

  return (
    <div className={s.form}>
      <ClientInfo className={s.form__client} />
      <ManagerForm formModel={managerForm} />
      <FixationForm formModel={fixationForm} className={s.form__fixation} />
    </div>
  );
};

export default withDictionaries({
  Component: observer(LeadFixation),
  dictionaries: [
    DictionaryFields.projects,
    DictionaryFields.placeTypes,
    DictionaryFields.employees,
    DictionaryFields.sources,
  ],
});
