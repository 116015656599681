import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Icon, { IconName } from '@/components/Icon';
import Typo, { TypoElement, TypoView } from '@/components/Typo';
import { useUiStore } from '@/stores/global/RootStore';

import s from './ResultModalStub.module.scss';

export type ResultModalStubProps = React.PropsWithChildren<{
  title: string;
  description?: string;
  iconName: IconName;
  iconHeight?: number;
  iconClassName?: string;
  className?: string;
}>;

const ResultModalStub: React.FC<ResultModalStubProps> = ({
  children,
  title,
  description,
  iconName,
  iconHeight,
  iconClassName,
  className,
}) => {
  const { isPhone } = useUiStore();

  return (
    <div className={cn(className, s.content)}>
      <div className={s.content_result}>
        <Icon iconName={iconName} height={iconHeight} width={300} className={iconClassName} />
        <Typo view={TypoView.h2} element={TypoElement.h2}>
          {title}
        </Typo>
        {description && <Typo view={isPhone ? TypoView.textSmall : TypoView.text}>{description}</Typo>}
      </div>
      {children}
    </div>
  );
};

export default observer(ResultModalStub);
