import * as React from 'react';

import s from './LotImage.module.scss';

type Props = {
  className?: string;
  src: string;
  alt?: string;
};

import cn from 'classnames';

import { ANIMATION_TIME } from '@/configs/constants';

const LotImage: React.FC<Props> = ({ className, src, alt }: Props) => {
  const [showImage, setShowImage] = React.useState(false);

  // показываем картинку через 300ms (время анимации открытия модалки),
  // поскольку если картинка svg, то она медленно отрисовывает и анимация логает
  React.useEffect(() => {
    setTimeout(() => setShowImage(true), ANIMATION_TIME);
  }, []);

  return <img className={cn(className, s.image, showImage && s.image_show)} src={src} alt={alt} />;
};

export default LotImage;
