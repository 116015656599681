import cn from 'classnames';
import * as React from 'react';
import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';

import { useToggleState } from '@kts-front/hooks';

import Typo, { TypoElement, TypoView } from '@/components/Typo';

import OptionList from '../OptionList';
import SelectedList from '../SelectedList';
import SelectInputField from '../../SelectInputField';
import { BaseSelectProps, SelectSize, ValuesProps } from '../types';

import s from './SelectVertical.module.scss';

export type SelectVerticalProps<V extends string | number> = BaseSelectProps<V> &
  Partial<{
    size: SelectSize;
    placeholder: string;
    disabled: boolean;
    popupPosition?: PopupPosition | PopupPosition[];
    inputClassName?: string;
    withoutCaption?: boolean;
    full?: boolean;
  }>;

const SelectVertical = <V extends string | number>(
  {
    caption,
    className,
    multiple,
    onChange,
    onBlur,
    options,
    size = 'medium',
    values,
    disabled,
    popupPosition,
    inputClassName,
    withoutCaption,
    full = true,
    ...props
  }: SelectVerticalProps<V> & ValuesProps<V>,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.JSX.Element => {
  const { opened, open, close } = useToggleState();
  const [searchOption, setSearchOption] = React.useState<string | null>(null);

  const handleChange = React.useCallback(
    (id: V | null) => {
      onChange(id);

      if (!multiple) {
        close();
      }
    },
    [onChange, close, multiple],
  );

  React.useEffect(() => {
    if (!multiple || !opened) {
      setSearchOption(null);
    }
  }, [values, multiple, opened]);

  // Фильтруем опции по поиску
  const filteredOptions = React.useMemo(
    () =>
      searchOption
        ? options.filter(
            (item) =>
              item.label.toLowerCase().includes(searchOption.toLowerCase()) ||
              item.search?.toLowerCase().includes(searchOption.toLocaleLowerCase()),
          )
        : options,
    [options, searchOption],
  );

  // Создаем объект из массива опций, чтобы быстрее находить опцию по id
  const optionsMap = React.useMemo(() => {
    return options.reduce<Record<V, string>>(
      (acc, { id, label }) => {
        acc[id] = label;

        return acc;
      },
      {} as Record<V, string>,
    );
  }, [options]);

  // В инпут подставляем значение по приоритету: searchOption -> incomeValue
  const value = React.useMemo(() => {
    if (multiple) {
      return searchOption ?? '';
    } else {
      return searchOption ?? optionsMap[values.values().next().value as V];
    }
  }, [optionsMap, searchOption, values]);

  const isMediumSize = size === 'medium';

  const handleClose = React.useCallback(() => {
    close();
    onBlur?.();
  }, [close, onBlur]);

  return (
    <div className={cn(full && s.select_full)} ref={ref}>
      {isMediumSize && !withoutCaption && (
        <Typo className={s.select_caption} view={TypoView.buttonSmall} element={TypoElement.h6}>
          {caption}
        </Typo>
      )}
      <div className={cn(s.select, className)}>
        <Popup
          trigger={
            <SelectInputField
              {...props}
              caption={isMediumSize ? '' : caption}
              ariaLabel={caption}
              focused={opened}
              onChange={setSearchOption}
              value={value ?? ''}
              size={size}
              disabled={disabled}
              className={inputClassName}
            />
          }
          arrow={false}
          offsetX={isMediumSize ? -10 : -12}
          offsetY={isMediumSize ? 4.5 : 14.5}
          onClose={handleClose}
          onOpen={open}
          open={opened}
          position={popupPosition ?? 'bottom left'}
          nested
        >
          <OptionList
            className={className}
            open={opened}
            options={filteredOptions}
            values={values}
            onClick={handleChange}
            size={size}
          />
        </Popup>
      </div>
      {multiple && Boolean(values.size) && <SelectedList values={values} onChange={onChange} optionsMap={optionsMap} />}
    </div>
  );
};

export default React.forwardRef(SelectVertical) as unknown as <V extends string | number>(
  props: SelectVerticalProps<V> & ValuesProps<V> & { ref?: React.ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof SelectVertical>;
