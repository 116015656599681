import * as React from 'react';

import { IPushSubscribeStore } from './types';

/** Хук инициализации подписки на пуши */
export const useInitPushSubscribe = (store: IPushSubscribeStore) => {
  React.useEffect(() => {
    if (!store.canUsePushs) {
      return;
    }

    store.initSubscribe();
  }, [store.canSubscribe]);
};
