import { BulksServer, IBulk } from '@/entities/bulk';

export class BulkModel implements IBulk {
  readonly id;
  readonly label;
  readonly bulkNumber;
  readonly keyDate;
  readonly floorsCount;
  readonly block;

  constructor({ id, label, bulkNumber, keyDate, floorsCount, block }: IBulk) {
    this.id = id;
    this.label = label;
    this.bulkNumber = bulkNumber;
    this.keyDate = keyDate;
    this.floorsCount = floorsCount;
    this.block = block;
  }

  static fromJson(raw: BulksServer): IBulk {
    return new BulkModel({
      id: raw.id,
      label: raw.name,
      bulkNumber: raw.number,
      keyDate: raw.key_date ? new Date(raw.key_date) : null,
      floorsCount: raw.floors_count,
      block: raw.block,
    });
  }
}
