import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useUiStore } from '@/stores/global/RootStore';

import PageTitle from '../PageTitle';

import HeaderBurgerMenu from './HeaderBurgerMenu';
import HeaderNavigation from './HeaderNavigation';
import HeaderUserSection from './HeaderUserSection';

import s from './Header.module.scss';

type HeaderProps = {
  className?: string;
};

const Header: React.FC<HeaderProps> = ({ className }) => {
  const { isLessThanMediumDesktop } = useUiStore();

  return (
    <div className={cn(s.header, className)}>
      {isLessThanMediumDesktop ? (
        <HeaderBurgerMenu />
      ) : (
        <>
          <PageTitle />
          <HeaderNavigation />
        </>
      )}

      <HeaderUserSection />
    </div>
  );
};

export default observer(Header);
