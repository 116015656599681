import { computed, makeObservable, reaction, runInAction } from 'mobx';

import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import {
  NotificationDirection,
  NotificationListResponse,
  NotificationPayload,
  NotificationServer,
  NotificationType,
} from '@/entities/notification/server';
import { ListModel } from '@/models/ListModel';
import {
  FixationPeriodNotificationModel,
  InterestBidCreatedNotificationModel,
  NotificationModelType,
  OtherNotificationModel,
} from '@/models/NotificationModel';
import { ToggleModel } from '@/models/ToggleModel';
import { ValueModel } from '@/models/ValueModel';
import { IRootStore } from '@/stores/global/RootStore';
import { Nullable } from '@/types/values';

import { LocalStore } from '../LocalStore';

const LIMIT = 20;

/** Стор списка уведомлений */
export class NotificationListStore extends LocalStore {
  readonly list = new ListModel<NotificationModelType, number>({
    keys: [],
    entities: new Map(),
    limit: LIMIT,
  });
  readonly type = new ValueModel<Nullable<NotificationType>>(null);

  private readonly request = apiStore.createRequest<NotificationListResponse>({
    method: 'GET',
    url: apiUrls.notifications.list,
  });

  private _rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    super();

    this._rootStore = rootStore;

    makeObservable(this, {
      hasMore: computed,
      modalState: computed,
    });

    this.addReactions([
      reaction(
        () => this.type.value,
        () => {
          this.refetch();
        },
      ),
      reaction(
        () => this.modalState.isOpen,
        (val) => {
          if (!val) {
            this.list.reset();
            this.request.cancel();

            return;
          }

          this.refetch();
        },
      ),
    ]);
  }

  private _load = async (reset = false): Promise<BaseResponse> => {
    if (reset) {
      this.request.cancel();
    } else {
      if (this.list.loadingStage.isLoading) {
        return { isError: true };
      }
    }

    this.list.loadingStage.loading();

    const { data, isError } = await this.request.call({
      params: this._preparePayload(),
    });

    if (isError) {
      this.list.loadingStage.error();

      return { isError: true };
    }

    runInAction(() => {
      if (this.list.length === 0 || reset) {
        this.list.total.change(data.total);
      }

      this.list.fillByRawData(
        data.notifications,
        (rawItem) => ({
          entity: NotificationListStore.getNotificationModelFromJson(rawItem),
          key: rawItem.id,
        }),
        reset,
      );

      this._rootStore.userStore.user?.setUnreadNotificationsCount(data.unread_count);
      this.list.loadingStage.success();
    });

    return { isError: false };
  };

  loadMore = async (): Promise<void> => {
    this._load(false);
  };

  refetch = async (): Promise<void> => {
    this.list.reset();
    this._load(true);
  };

  private _preparePayload = (): NotificationPayload => {
    return {
      limit: this.list.limit,
      notification_id: this.list.keys[this.list.keys.length - 1] ?? null,
      direction: NotificationDirection.bottom,
      types: this.type.value ?? '',
    };
  };

  get hasMore(): boolean {
    return this.list.length < this.list.total.value && !this.list.loadingStage.isLoading;
  }

  resetFilters = (): void => {
    this.type.reset();
  };

  get hasSelectedFilters(): boolean {
    return Boolean(this.type.value);
  }

  destroy(): void {
    super.destroy();
    this.request.destroy();
  }

  get modalState(): ToggleModel {
    return this._rootStore.modalsStore.notificationListModal;
  }

  static getNotificationModelFromJson(raw: NotificationServer): NotificationModelType {
    switch (raw.type) {
      case NotificationType.fixationPeriod: {
        return FixationPeriodNotificationModel.fromJson(raw);
      }

      case NotificationType.interestBidCreated: {
        return InterestBidCreatedNotificationModel.fromJson(raw);
      }

      default: {
        return OtherNotificationModel.fromJson(raw);
      }
    }
  }
}
