import { autoUpdate, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useToggleState } from '@kts-front/hooks';

import OptionInfiniteList from '@/components/form/OptionInfiniteList';
import PhoneField from '@/components/form/PhoneField';
import { ClientSearchOption } from '@/stores/global/FixationModalStore/ClientFixationStore';
import { useFixationModalStore } from '@/stores/global/RootStore';

import ClientSearchItem from './ClientSearchItem';
import EmptyState from './EmptyState';

import s from './ClientsInfiniteList.module.scss';

export type Props = {
  infiniteListHeight: number;
};

const ClientsInfiniteList = ({ infiniteListHeight }: Props): React.JSX.Element => {
  const {
    selectClient,
    clientFixationStore: { clientList, searchOptions, loadMoreClients, changeSearch, clientPhone },
  } = useFixationModalStore();

  const { opened, toggle, open, close } = useToggleState();

  const { refs, strategy, floatingStyles, context } = useFloating({
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    open: opened,
    onOpenChange: toggle,
    middleware: [offset(15)],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const handleChange = React.useCallback(
    (id: string) => {
      selectClient(String(id));
      close();
    },
    [selectClient, close],
  );

  const listItem = React.useCallback(
    (item: ClientSearchOption, checked: boolean, onClick: (id: string) => void) => (
      <ClientSearchItem
        key={item.id}
        className={cn(s.list__item, checked && s.list__item_checked)}
        item={item.client}
        onClick={() => onClick(item.id)}
      />
    ),
    [],
  );

  return (
    <div className={s.wrapper}>
      <PhoneField
        {...clientPhone.props}
        ref={refs.setReference}
        value={clientPhone.value ?? ''}
        focused={opened}
        onChange={changeSearch}
        onFocus={open}
        {...getReferenceProps()}
      />

      {opened && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={{ ...floatingStyles, position: strategy, zIndex: 100 }}
        >
          <OptionInfiniteList<string, ClientSearchOption>
            className={s.wrapper}
            open={opened}
            options={searchOptions}
            value={clientPhone.value}
            onClick={handleChange}
            loadMore={loadMoreClients}
            hasMore={clientList.hasMore}
            loading={clientList.list.loadingStage.value}
            renderItem={listItem}
            infiniteListHeight={infiniteListHeight}
            emptyState={<EmptyState close={close} />}
          />
        </div>
      )}
    </div>
  );
};

export default observer(ClientsInfiniteList);
