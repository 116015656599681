import * as React from 'react';

import { ClientFormFields } from '@/components/clients/ClientFormFields';
import Typo, { TypoElement, TypoView } from '@/components/Typo';
import { useFixationModalStore } from '@/stores/global/RootStore';

import s from './ClientForm.module.scss';

const ClientForm: React.FC = () => {
  const {
    clientFixationStore: {
      clientCreation: { clientForm },
    },
  } = useFixationModalStore();

  return (
    <div className={s.block}>
      <Typo view={TypoView.h3} element={TypoElement.h3} block>
        Личные данные
      </Typo>
      <ClientFormFields formModel={clientForm} />
    </div>
  );
};

export default ClientForm;
