import cn from 'classnames';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from '@/components/Loader';
import Typo, { TypoView } from '@/components/Typo';
import { LoadingStage } from '@/types/meta';

import OptionItem from '../Select/OptionItem';

import s from './OptionInfiniteList.module.scss';

const EMPTY_STATE = (
  <div className={s.list_empty}>
    <Typo view={TypoView.h5}>Список пуст</Typo>
  </div>
);

type Props<V extends string | number, T extends { id: V }> = {
  className?: string;
  onClick: (id: V) => void;
  open: boolean;
  options: T[];
  value: V | null;
  loadMore: () => void;
  hasMore: boolean;
  loading: LoadingStage;
  infiniteListHeight: number;
  hasFixedHeight?: boolean;
  renderItem?: (item: any, checked: boolean, onClick: (id: V) => void) => React.ReactNode;
  emptyState?: React.ReactNode;
};

const OptionInfiniteList = <V extends string | number, T extends { id: V }>({
  className,
  onClick,
  open,
  options,
  value,
  loadMore,
  hasMore,
  loading,
  infiniteListHeight,
  hasFixedHeight = false,
  renderItem,
  emptyState = EMPTY_STATE,
}: Props<V, T>): React.ReactElement => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const getScrollParent = React.useCallback(() => containerRef.current, []);

  const isLoading = loading === LoadingStage.loading;

  return (
    <div
      className={cn(s.list, open && s.list_open, className)}
      ref={containerRef}
      style={{
        maxHeight: open ? `${infiniteListHeight}px` : 0,
        minHeight: hasFixedHeight ? `${infiniteListHeight}px` : 0,
      }}
    >
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        useWindow={false}
        getScrollParent={getScrollParent}
        initialLoad={false}
      >
        <>
          {options &&
            options.map((item: any) =>
              renderItem ? (
                renderItem(item, value === item.label, onClick)
              ) : (
                <OptionItem key={item.id} item={item} checked={value === item.label} onClick={onClick} />
              ),
            )}
          {!options.length && !isLoading && emptyState}
          {isLoading && <Loader className={s.list__loader} />}
        </>
      </InfiniteScroll>
    </div>
  );
};

export default React.memo(OptionInfiniteList) as typeof OptionInfiniteList;
