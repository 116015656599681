import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import Typo, { TypoAlign, TypoView } from '@/components/Typo';
import { FixationCreationStage } from '@/stores/global/FixationModalStore';
import { useFixationModalStore } from '@/stores/global/RootStore';

import s from './EmptyState.module.scss';

type Props = {
  close: () => void;
};

const EmptyState: React.FC<Props> = ({ close }) => {
  const { goToClientCreation, stage } = useFixationModalStore();

  const handleClick = React.useCallback(() => {
    goToClientCreation();
    close();
  }, []);

  return stage === FixationCreationStage.clientForm ? (
    <div className={s.state}>
      <Typo view={TypoView.h5} textAlign={TypoAlign.center}>
        Такого клиента нет
        <br />в нашей базе
      </Typo>
      {/* a - потому что с button инпут теряет фокус при рендеринге */}
      <Button element="a" size={ButtonSize.small} view={ButtonType.secondary} onClick={handleClick}>
        Добавить нового
      </Button>
    </div>
  ) : null;
};

export default observer(EmptyState);
