export enum RouteSegment {
  // "Список заявок"
  leads = 'leads',
  // "Список клиентов"
  clients = 'clients',
  // "Детальная страница клиента"
  client = 'client',
  // "Список сотрудников"
  employees = 'employees',
  // "Профиль агенства"
  agency = 'agency',
  // "Бронирование"
  booking = 'booking',
  // "Тарифная карта"
  rateCard = 'rate-card',
  // "Лоты"
  lots = 'lots',
  // "Подборки"
  selections = 'selections',
  // "Подборка лотов клиента"
  clientLotsSelection = 'selection',
}
