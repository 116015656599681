import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { routerUrls } from '@/configs/routes';
import { ownLocalStorage, StorageKeys } from '@/models/LocaleStorageModel';
import { useUiStore } from '@/stores/global/RootStore';

import Button, { ButtonSize, ButtonType } from '../Button';
import Icon from '../Icon';
import IconButton from '../IconButton';
import { TypoView } from '../Typo';

import s from './RewardBar.module.scss';

/** Миллисекунд в месяце */
const REWARD_BAR_HIDE_TIME = 43800 * 60 * 1000;

type RewardBarProps = {
  isBarShown: boolean;
};

const RewardBar: React.FC<RewardBarProps> = ({ isBarShown }) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(true);

  const { isPhone } = useUiStore();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(routerUrls.rateCard.create());
  };

  const handleClose = React.useCallback(() => {
    const now = new Date().getTime();
    ownLocalStorage.setItem(StorageKeys.rewardBarClosedTime, now.toString());
    setIsVisible(false);
  }, []);

  React.useEffect(() => {
    const lastClosedTime = ownLocalStorage.getItem(StorageKeys.rewardBarClosedTime);

    if (lastClosedTime) {
      const now = new Date().getTime();

      if (now - Number(lastClosedTime) < REWARD_BAR_HIDE_TIME) {
        setIsVisible(false);
      } else {
        ownLocalStorage.removeItem(StorageKeys.rewardBarClosedTime);
      }
    }
  }, []);

  if (isVisible && isBarShown) {
    return (
      <div className={s.bar}>
        <Button
          size={ButtonSize.custom}
          view={ButtonType.ghost}
          textView={isPhone ? TypoView.textMedium : TypoView.text}
          onClick={handleNavigate}
          className={s.bar__link}
          after={<Icon iconName="IconArrowLeft" size={isPhone ? 22 : 28} iconRotation={180} color="white" />}
        >
          Вознаграждение
        </Button>

        <IconButton
          iconName="IconClose"
          size={isPhone ? 22 : 28}
          color="white"
          onClick={handleClose}
          className={s.bar__close}
        />
      </div>
    );
  }

  return null;
};

export default observer(RewardBar);
