import { runInAction } from 'mobx';

import { apiStore, apiUrls } from '@/api';
import { LeadServer } from '@/entities/lead/list';
import { LeadModel } from '@/models/LeadModel';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { ToggleModel } from '@/models/ToggleModel';
import { ValueModel } from '@/models/ValueModel';
import { Nullable } from '@/types/values';

export interface ILeadsStore {
  detailLeadModal: ToggleModel;
  createLeadModal: ToggleModel;
  loadingStage: LoadingStageModel;
  open(params: OpenParams): void;
  close(): void;
  fetchDetail(leadId: number): Promise<void>;
  get lead(): ValueModel<Nullable<LeadModel>>;
  isNotificationBack: ValueModel<boolean>;
}

export enum LeadModalStoreOpenType {
  id = 'id',
  entity = 'entity',
}

type OpenParams = (
  | { type: LeadModalStoreOpenType.id; value: number }
  | { type: LeadModalStoreOpenType.entity; value: LeadModel }
) & {
  isNotificationBack?: boolean;
};

export class LeadModalStore implements ILeadsStore {
  readonly createLeadModal: ToggleModel = new ToggleModel();
  readonly detailLeadModal: ToggleModel = new ToggleModel();

  private readonly _request = apiStore.createRequest<LeadServer>({ method: 'GET' });
  readonly loadingStage = new LoadingStageModel();

  readonly lead = new ValueModel<Nullable<LeadModel>>(null);
  readonly isNotificationBack = new ValueModel<boolean>(false);

  open(params: OpenParams): void {
    this.detailLeadModal.open();

    this.isNotificationBack.change(params.isNotificationBack ?? false);

    if (params.type === LeadModalStoreOpenType.id) {
      this.fetchDetail(params.value);
    } else {
      this.lead.change(params.value);
    }
  }

  close = (): void => {
    if (this.loadingStage.isLoading) {
      this._request.cancel();
    }

    this.detailLeadModal.close();
    this.lead.reset();
    this.isNotificationBack.reset();
  };

  async fetchDetail(leadId: number): Promise<void> {
    if (this.loadingStage.isLoading) {
      this._request.cancel();
    }

    this.loadingStage.loading();

    const response = await this._request.call({
      url: apiUrls.leads.item.get(leadId),
    });

    runInAction(() => {
      if (response.isError || !response.data) {
        this.loadingStage.error();

        this.lead.reset();

        return;
      }

      this.lead.change(LeadModel.fromJson(response.data));
      this.loadingStage.success();
    });
  }
}
