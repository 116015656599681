import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import Typo, { TypoAlign, TypoView, TypoWeight } from '@/components/Typo';
import { useFixationModalStore } from '@/stores/global/RootStore';

import s from './ClientSearchStub.module.scss';

type ClientSearchStubProps = {
  stubHeight: number;
};

const ClientSearchStub: React.FC<ClientSearchStubProps> = ({ stubHeight }) => {
  const { goToClientCreation } = useFixationModalStore();

  return (
    <div className={s.stub} style={{ height: `${stubHeight}px` }}>
      <div className={s.stub__info}>
        <Typo view={TypoView.h4} weight={TypoWeight.medium} textAlign={TypoAlign.center}>
          Такого клиента нет
          <br />в нашей базе
        </Typo>
      </div>
      <div className={s.stub__actions}>
        <Typo view={TypoView.textSmall}>Не нашли клиента?</Typo>
        <Button onClick={goToClientCreation} full size={ButtonSize.large} view={ButtonType.secondary} element="button">
          Добавить нового
        </Button>
      </div>
    </div>
  );
};

export default observer(ClientSearchStub);
