import cn from 'classnames';
import * as React from 'react';

import IconButton from '@/components/IconButton';
import PageTitle from '@/components/PageTitle';

import s from './TopWindow.module.scss';

export type TopWindowProps = React.PropsWithChildren<{
  className?: string;
  childrenClassName?: string;
  showCloseButton?: boolean;
  onClose: VoidFunction;
}>;

const TopWindow: React.FC<TopWindowProps> = ({
  className,
  children,
  childrenClassName,
  showCloseButton = true,
  onClose,
}) => {
  return (
    <div className={s.content_wrapper}>
      <div className={cn(s.content, className)}>
        <PageTitle className={s.content_title} />
        <div className={cn(s.content_children, childrenClassName)}>{children}</div>
        {showCloseButton && (
          <IconButton
            className={s.content_closeIcon}
            iconName="IconClose"
            onClick={onClose}
            size={44}
            color="grey-main"
            aria-label="закрыть"
          />
        )}
      </div>
    </div>
  );
};

export default TopWindow;
