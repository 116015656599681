import * as React from 'react';

import { useRootStore } from '@/stores/global/RootStore';

/** Для того, чтобы при создании новой заявки/клиента из деталки заявки, страница обновлялась */
export const useRefetchWhenCreate = (refetch: VoidFunction) => {
  const rootStore = useRootStore();

  React.useEffect(() => {
    rootStore.modalsStore.fixationModalStore.refetchFunction.change(refetch);

    return () => {
      rootStore.modalsStore.fixationModalStore.refetchFunction.reset();
    };
  }, []);
};
