import cn from 'classnames';
import * as React from 'react';

import BaseClickable from '@/components/BaseClickable';
import Typo, { TypoView, TypoWeight } from '@/components/Typo';
import { useMatchLinks } from '@/utils/useMatchLinks';

import { NavItemProps } from '../../types';

import s from './NavItemMain.module.scss';

const NavItemMain: React.FC<NavItemProps> = ({ text, link, activeRoutes }) => {
  const isActive = useMatchLinks(activeRoutes ?? []);

  return (
    <BaseClickable {...link}>
      <Typo
        view={TypoView.text}
        weight={TypoWeight.medium}
        color={isActive ? 'black' : 'text-grey'}
        className={cn(s.item__text, isActive && s.item__text_active)}
      >
        {text}
      </Typo>
    </BaseClickable>
  );
};

export default React.memo(NavItemMain);
