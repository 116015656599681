import { useMemo } from 'react';

import { InfoItem } from '@/components/InfoGrid';
import { CrmRejectionInfo } from '@/entities/lead/list';
import { PhoneNumber } from '@/models/PhoneNumber';
import { Nullable } from '@/types/values';
import { formatDDMMYY } from '@/utils/formatDate';

export const useFormattedInfo = (crmRejectionInfo: Nullable<CrmRejectionInfo>) => {
  const leadInfo: Nullable<InfoItem[][]> = useMemo(() => {
    const info: InfoItem[] = [];

    if (crmRejectionInfo?.holdDate) {
      info.push({ label: 'Дата закрепления', value: formatDDMMYY(crmRejectionInfo.holdDate) });
    }

    return info.length ? [info] : null;
  }, [crmRejectionInfo]);

  const managerInfo: Nullable<InfoItem[][]> = useMemo(() => {
    const info: InfoItem[] = [];
    const { agency, manager } = crmRejectionInfo ?? {};

    if (agency?.title) {
      info.push({ label: 'Агентство', value: agency.title });
    }

    if (manager?.name) {
      info.push({ label: 'Ответственный менеджер', value: manager.name });
    }

    if (manager?.phone) {
      info.push({ label: 'Телефон', value: PhoneNumber.format(manager.phone) });
    }

    return info.length ? [info] : null;
  }, [crmRejectionInfo]);

  return {
    leadInfo,
    managerInfo,
  };
};
