import * as React from 'react';

import Button, { ButtonType } from '@/components/Button';
import Icon from '@/components/Icon';
import Typo, { TypoView, TypoWeight } from '@/components/Typo';

import s from './PopupContent.module.scss';

type PopupContentProps = {
  trySubscribe: () => Promise<void>;
  rejectSubscribe: VoidFunction;
};

const PopupContent: React.FC<PopupContentProps> = ({ trySubscribe, rejectSubscribe }) => {
  return (
    <div className={s.content}>
      <Icon iconName="IconNotificationsSubscribe" />
      <Typo view={TypoView.text} weight={TypoWeight.medium}>
        Чтобы получать уведомления по клиентам — подпишитесь
      </Typo>
      <div className={s.content__buttons}>
        <Button onClick={trySubscribe}>Подписаться</Button>
        <Button onClick={rejectSubscribe} view={ButtonType.secondary} textView={TypoView.text}>
          Позже
        </Button>
      </div>
    </div>
  );
};

export default React.memo(PopupContent);
