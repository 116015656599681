import { action, computed, makeObservable, observable } from 'mobx';

import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/api';
import { SubscriptionError } from '@/errors';
import { LoadingStageModel } from '@/models/LoadingStageModel';
import { ownLocalStorage, StorageKeys } from '@/models/LocaleStorageModel';
import { ToggleModel } from '@/models/ToggleModel';
import { captureException } from '@/utils/captureException';

import { IRootStore } from '../RootStore';

import { IPushSubscribeStore } from './types';
import { urlBase64ToUint8Array } from './utils';

type Params = {
  rootStore: IRootStore;
};

type PrivateFields = '_registration' | '_subscription' | '_setSubscription' | '_start';

const OPEN_DELAY = 1000;
const WEEKLY_DELAY = 1000 * 3600 * 24 * 7;

export class PushSubscribeStore implements IPushSubscribeStore {
  readonly modal = new ToggleModel();
  private readonly _publicKeyRequest = apiStore.createRequest<{ public_key: string }>({
    method: 'GET',
    url: apiUrls.notifications.getPublicKey,
  });

  private readonly _subscribeRequest = apiStore.createRequest({
    method: 'POST',
    url: apiUrls.notifications.subscribe,
  });

  private readonly _unsubscribeRequest = apiStore.createRequest({
    method: 'POST',
    url: apiUrls.notifications.removeSubscription,
  });

  private _registration: ServiceWorkerRegistration | null = null;
  private _subscription: PushSubscription | null = null;
  readonly loadingStage = new LoadingStageModel();

  private readonly _rootStore: IRootStore;

  constructor({ rootStore }: Params) {
    this._rootStore = rootStore;

    makeObservable<this, PrivateFields>(this, {
      _registration: observable.ref,
      _subscription: observable,
      _start: action,
      _setSubscription: action,
      canSubscribe: computed,
    });

    this._start();
  }

  get canSubscribe(): boolean {
    return Boolean(
      this._rootStore.userStore.authorized &&
        this._registration &&
        !this._subscription &&
        !this._rootStore.userStore.user?.isBlocked &&
        !this._rootStore.userStore.logoutStage.isLoading,
    );
  }

  get canUsePushs(): boolean {
    return (
      'Notification' in window &&
      Notification.permission !== 'denied' &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    );
  }

  initSubscribe(): void {
    if (!this.canSubscribe) {
      return;
    }

    if (Notification.permission === 'granted') {
      this._subscribe();

      return;
    }

    if (!this._isTimeToShowModal()) {
      return;
    }

    setTimeout(() => this.modal.open(), OPEN_DELAY);
  }

  trySubscribe = async (): Promise<void> => {
    const result = await Notification.requestPermission();
    this.modal.close();

    if (result === 'granted') {
      this._subscribe();
    }
  };

  async unsubscribe(): Promise<void> {
    if (!this._subscription) {
      return;
    }

    await this._subscription.unsubscribe();
    await this._unsubscribeRequest.call({
      data: {
        endpoint: this._subscription.endpoint,
      },
      method: 'POST',
    });
    this._setSubscription(null);
  }

  closeModal(): void {
    ownLocalStorage.setItem(StorageKeys.pushSubscribeModalClosedTime, String(Date.now()));
    this.modal.close();
  }

  private _isTimeToShowModal(): boolean {
    const lastClosedTime = ownLocalStorage.getItem(StorageKeys.pushSubscribeModalClosedTime);

    if (!lastClosedTime) {
      return true;
    }

    const lastClosedDate = new Date(Number(lastClosedTime));
    const now = new Date();
    const timeDifference = now.getTime() - lastClosedDate.getTime();

    return timeDifference > WEEKLY_DELAY;
  }

  private async _start(): Promise<void> {
    if (!this.canUsePushs) {
      return;
    }

    const registration = await navigator.serviceWorker.ready;
    this._registration = registration;

    const subscription = await registration.pushManager.getSubscription();
    this._setSubscription(subscription);
  }

  private async _serverSubscribe(subscription: PushSubscription | null): Promise<BaseResponse> {
    if (!subscription) {
      captureException(new SubscriptionError('subscription is null'), 'error', {
        email: this._rootStore.userStore.user?.email,
      });

      return { isError: true };
    }

    const keys = subscription.toJSON().keys;

    if (!keys?.auth || !keys?.p256dh) {
      captureException(new SubscriptionError('subscription does not have keys'), 'error', {
        subscription,
        keys,
        email: this._rootStore.userStore.user?.email,
      });

      return { isError: true };
    }

    const { isError } = await this._subscribeRequest.call({
      data: {
        endpoint: subscription.endpoint,
        keys: { auth: keys.auth, p256dh: keys.p256dh },
      },
    });

    if (isError) {
      captureException(new SubscriptionError('error while making request to server'), 'error', {
        subscription,
        keys,
        email: this._rootStore.userStore.user?.email,
      });
    }

    return { isError };
  }

  private async _subscribe(): Promise<void> {
    if (!this._registration || this.loadingStage.isLoading) {
      return;
    }

    try {
      this.loadingStage.loading();
      const response = await this._publicKeyRequest.call();

      if (response.isError) {
        this.loadingStage.error();

        return;
      }

      const applicationServerKey = urlBase64ToUint8Array(response.data.public_key);
      const newSubscription = await this._registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      });

      const { isError: isErrorSubscribe } = await this._serverSubscribe(newSubscription);

      if (isErrorSubscribe) {
        this.loadingStage.error();
        newSubscription.unsubscribe();
      } else {
        this._setSubscription(newSubscription);
        this.loadingStage.success();
      }
    } catch (error) {
      this.loadingStage.error();
      captureException(error, 'subscribe error');
    }
  }

  private _setSubscription(subscription: PushSubscription | null): void {
    this._subscription = subscription;
  }
}
