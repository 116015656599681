export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  secondaryOutline = 'secondary-outline',
  whiteOutline = 'white-outline',
  outline = 'outline',
  ghost = 'ghost',
  variable = 'variable',
  white = 'white',
}

export enum ButtonSize {
  large = 'l',
  medium = 'm',
  small = 's',
  xs = 'xs',
  custom = 'custom',
}
