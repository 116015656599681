import { computed, makeObservable } from 'mobx';

import { ApiRequest, ApiRequestParams } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';

import { apiStore } from '@/api';

import { ListModel } from './ListModel';
import { ValueModel } from './ValueModel';

type Params<Model, ServerData extends Record<string, any>, ListResponse, Key extends PropertyKey = string> = {
  limit: number;
  normalizer: (raw: ServerData) => { entity: Model; key: Key };
  getEntities(raw: ListResponse): { entities: ServerData[]; total: number };

  requestParams?: ApiRequestParams['requestParams'];
  loaderDelay?: number;
};

export class InfiniteListModel<
  Model,
  ServerData extends Record<string, any>,
  ListResponse,
  Key extends PropertyKey = string,
> {
  readonly list = new ListModel<Model, Key>();
  readonly isAllLoaded = new ValueModel<boolean>(false);

  private readonly _params: Params<Model, ServerData, ListResponse, Key>;
  private readonly _request: ApiRequest<ListResponse>;

  constructor(params: Params<Model, ServerData, ListResponse, Key>) {
    this.list = new ListModel<Model, Key>({
      keys: [],
      entities: new Map(),
      limit: params.limit,
    });
    this._params = params;

    this._request = apiStore.createRequest<ListResponse>(params.requestParams);

    makeObservable(this, {
      hasMore: computed,
    });
  }

  get hasMore(): boolean {
    return !this.isAllLoaded.value && this.list.loadingStage.isSuccess;
  }

  load = async ({ reset = true, data }: { reset?: boolean; data: Record<string, unknown> }): Promise<BaseResponse> => {
    if (reset) {
      this._request.cancel();
    } else {
      if (this.list.loadingStage.isLoading) {
        return { isError: true };
      }
    }

    this.list.loadingStage.loading();

    const response = await this._request.call(
      this._params.requestParams?.method === 'POST' ? { data } : { params: data },
    );

    if (response.isError) {
      this._finishLoading('error');

      return { isError: true };
    }

    const { entities, total } = this._params.getEntities(response.data);

    this.list.fillByRawData(entities, this._params.normalizer, reset);

    this.list.total.change(total);
    this.isAllLoaded.change(entities.length < this.list.limit);

    this._finishLoading('success');

    return { isError: false };
  };

  loadMore = async (data: Record<string, unknown>): Promise<BaseResponse> => {
    if (this.list.loadingStage.isLoading) {
      return { isError: false };
    }

    this.list.curPage.change(this.list.curPage.value + 1);

    return this.load({ reset: false, data });
  };

  cancelRequest(): void {
    this._request.cancel();
  }

  reset(): void {
    this.list.reset();
    this.isAllLoaded.change(false);
  }

  private _finishLoading(method: 'success' | 'error'): void {
    const func = this.list.loadingStage[method].bind(this);

    if (this._params.loaderDelay) {
      setTimeout(() => func(), this._params.loaderDelay);
    } else {
      func();
    }
  }
}
