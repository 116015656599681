import { observer } from 'mobx-react-lite';
import * as React from 'react';

import BaseClickable from '@/components/BaseClickable';
import { ModalVariant } from '@/components/Modal';
import Overlay from '@/components/Modal/Overlay';
import PageTitle from '@/components/PageTitle';
import Typo, { TypoView } from '@/components/Typo';
import { ANIMATION_TIME } from '@/configs/constants';
import { useUiStore } from '@/stores/global/RootStore';

import { NavItem } from '../../config';
import { NavItemProps } from '../../types';

import s from './MenuModal.module.scss';

type MenuModalProps = {
  onClose: VoidFunction;
  opened: boolean;
  items: NavItem[];
  linksConfig: Record<NavItem, NavItemProps>;
};

const MenuModal: React.FC<MenuModalProps> = ({ onClose, opened, items, linksConfig }) => {
  const { isTinyDesktop, isTablet } = useUiStore();

  const offsetY = isTinyDesktop ? 77.5 : isTablet ? 69.5 : 57.5;

  return (
    <Overlay
      variant={ModalVariant.CustomPortal}
      onClose={onClose}
      opened={opened}
      ariaLabel="Меню"
      withBackdrop
      animationTime={ANIMATION_TIME}
      overlayClassName={s.content__overlay}
    >
      <div className={s.content} style={{ top: offsetY }}>
        <div className={s.content__links}>
          {items.map((item, idx) => {
            const { text, link } = linksConfig[item];

            return (
              <BaseClickable key={idx} {...link} onClick={onClose}>
                <Typo view={TypoView.h4} color="text-grey" className={s['content__links-item']}>
                  {text}
                </Typo>
              </BaseClickable>
            );
          })}
        </div>
        <PageTitle hasLargeLogo titleView={TypoView.buttonSmall} captionClassName={s.content__caption} />
      </div>
    </Overlay>
  );
};

export default observer(MenuModal);
