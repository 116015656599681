import cn from 'classnames';
import * as React from 'react';

import Typo, { TypoView } from '@/components/Typo';
import { BaseClientModel } from '@/models/BaseClientModel';

import s from './ClientSearchItem.module.scss';

type ClientSearchItemProps = {
  className?: string;
  item: BaseClientModel;
  onClick: (id: string | number) => void;
};

const ClientSearchItem: React.FC<ClientSearchItemProps> = ({ className, item, onClick }) => {
  const handleClick = React.useCallback(() => {
    onClick(item.id);
  }, [item.id, onClick]);

  return (
    <div className={cn(s.client, className)} onClick={handleClick}>
      <div className={s.client__content}>
        <Typo view={TypoView.h6} color="black">
          {item.clientFullName}
        </Typo>
        <Typo className={s.client__subinfo} view={TypoView.textSmall} color="text-grey">
          <span>{item.phone}</span>
          {item.email && <span>&middot;</span>}
          <span>{item.email}</span>
        </Typo>
      </div>
    </div>
  );
};

export default React.memo(ClientSearchItem);
