import * as React from 'react';

import { LeadStatus as ELeadStatus } from '@/entities/lead/base';
import { ILead, LeadResultModalState, leadStatusEntities } from '@/entities/lead/list';
import { CreateLeadResultType } from '@/entities/modal';
import { Nullable } from '@/types/values';

import CreateLeadResultModal from '../CreateLeadResultModal';

import s from './LeadStatus.module.scss';

const LeadStatus: React.FC<{ item: ILead }> = ({ item }) => {
  const status = leadStatusEntities[item.status];

  const [resultModalState, setResultModalState] = React.useState<Nullable<LeadResultModalState>>(null);

  const openResultModal = React.useCallback(() => {
    setResultModalState({
      type: CreateLeadResultType.refused,
      crmRejectionInfo: item.crmRejectionInfo,
    });
  }, [item]);

  const closeResultModal = React.useCallback(() => {
    setResultModalState(null);
  }, []);

  return (
    <>
      <CreateLeadResultModal state={resultModalState} onClose={closeResultModal} />
      {status.label}
      {status.id == ELeadStatus.nonUnique && (
        <p className={s.status_notUnique} role="button" onClick={openResultModal}>
          Смотреть причину
        </p>
      )}
    </>
  );
};

export default LeadStatus;
