import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/stores/global/RootStore';

import FixationModal from '../FixationModal';
import PWATutorialModal from '../PWATutorialModal';
import TemporaryInfoModal from '../TemporaryInfoModal';
import DetailLeadModal from '../leads/DetailLeadModal';
import MeetingNoticeModal from '../leads/MeetingNoticeModal';

const Modals: React.FC = () => {
  const {
    PWATutorialModalStore: { isPWAModalAvailable },
  } = useRootStore().modalsStore;

  return (
    <>
      <DetailLeadModal />
      <MeetingNoticeModal />
      <FixationModal />
      {isPWAModalAvailable && <PWATutorialModal />}
      <TemporaryInfoModal />
    </>
  );
};

export default observer(Modals);
