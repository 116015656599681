import { BaseLeadServer, IBaseLead } from '@/entities/lead/base';
import { formatDDMMYY } from '@/utils/formatDate';
import { getFullName } from '@/utils/getFullName';

export class BaseLeadModel implements IBaseLead {
  readonly managerId;
  readonly managerFullName;
  readonly id;
  readonly status;
  readonly placeType;
  readonly createdDate;
  readonly expiryDate;
  readonly project;
  readonly comment;
  readonly subAgent;

  constructor({
    managerId,
    managerFullName,
    id,
    status,
    placeType,
    createdDate,
    expiryDate,
    project,
    comment,
    subAgent,
  }: IBaseLead) {
    this.managerId = managerId;
    this.managerFullName = managerFullName;
    this.id = id;
    this.status = status;
    this.placeType = placeType;
    this.createdDate = createdDate;
    this.expiryDate = expiryDate;
    this.project = project;
    this.comment = comment;
    this.subAgent = subAgent;
  }

  get createdDateFormatted(): string {
    return formatDDMMYY(this.createdDate);
  }

  get expiryDateFormatted(): string {
    return this.expiryDate ? formatDDMMYY(this.expiryDate) : '';
  }

  get subAgentInfo(): string | null {
    if (!this.subAgent) {
      return null;
    }

    return `${this.subAgent.name}, ${this.subAgent.phone}`;
  }

  static baseLeadFromJson(raw: BaseLeadServer): BaseLeadModel {
    const subAgent =
      raw.sub_agent?.name && raw.sub_agent?.phone
        ? {
            name: raw.sub_agent.name,
            phone: raw.sub_agent.phone,
          }
        : null;

    return new BaseLeadModel({
      managerId: raw.manager.id,
      managerFullName: getFullName({
        firstName: raw.manager.first_name,
        lastName: raw.manager.last_name,
      }),
      id: raw.id,
      status: raw.status,
      placeType: raw.place_type,
      createdDate: raw.created_at,
      expiryDate: raw.hold_date,
      project: raw.project.name,
      comment: raw.comment,
      subAgent,
    });
  }
}
