const getUserPrefix = (endpoint: string): string => `/user/${endpoint}`;
const getEmployeePrefix = (endpoint: string): string => `/employee/${endpoint}`;
const getClientPrefix = (endpoint: string): string => `/client/${endpoint}`;
const getNotificationsPrefix = (endpoint: string): string => `/notifications/${endpoint}`;
const getBookingPrefix = (endpoint: string): string => `/booking/${endpoint}`;
const getBankPrefix = (endpoint: string): string => `/bank_details/${endpoint}`;
const getSelectionPrefix = (endpoint: string): string => `/selection/${endpoint}`;

export const apiUrls = {
  dictionaries: {
    projects: '/project/list',
    placeTypes: '/placetype/list',
  },
  sources: {
    list: '/source/list',
  },
  leads: {
    list: '/bid/list',
    create: '/v2/bid/create',
    item: {
      get: (id: number) => `/bid/${id}`,
    },
  },
  client: {
    item: {
      get: (id: number) => `/client/${id}`,
      bids: (id: number) => `/client/${id}/bids`,
      selections: (id: number) => `/client/${id}/selections`,
    },
    list: getClientPrefix('list'),
    /** Простой список для фиксации клиента */
    simpleList: getClientPrefix('list/simple'),
    create: getClientPrefix('create'),
  },
  user: {
    current: getUserPrefix('current'),
    logout: getUserPrefix('logout'),
  },
  registration: '/registration',
  employee: {
    list: getEmployeePrefix('list'),
    edit: getEmployeePrefix('edit'),
    block: getEmployeePrefix('block'),
    unblock: getEmployeePrefix('unblock'),
    create: getEmployeePrefix('create'),
  },

  notifications: {
    list: getNotificationsPrefix('list'),
    getPublicKey: getNotificationsPrefix('get_public_key'),
    subscribe: getNotificationsPrefix('subscribe'),
    removeSubscription: getNotificationsPrefix('remove_subscription'),
  },

  booking: {
    client: getBookingPrefix('client/list'),
    create: '/booking',
    // api нужен, тк это ссылка для скачивания файла
    offer: (bookingId: number) => `/api/booking/offer?booking_id=${bookingId}`,
  },

  agency: {
    editCommonInfo: '/agency',
  },

  bank: {
    bankDetailsCreate: getBankPrefix('create'),
    bankDetailsEdit: getBankPrefix('edit'),
  },

  rateCard: {
    list: '/rate_card',
  },

  flats: {
    list: '/flats',
  },

  bulks: {
    list: (projectId: number) => `/bulks/${projectId}`,
  },

  selection: {
    item: '/selection',
    flats: getSelectionPrefix('flats'),
    flat: (id: number) => `${getSelectionPrefix(`flats/${id}`)}`,
    confirm: getSelectionPrefix('confirm'),
    interest: getSelectionPrefix('interest'),
    client: () => getSelectionPrefix('client/flats'),
    clientList: getSelectionPrefix('client/list'),
    filters: getSelectionPrefix('flats/filters'),
    removeLot: (selectionId: number, flatId: number) => getSelectionPrefix(`${selectionId}/flat/${flatId}`),
    list: getSelectionPrefix('list'),
    delete: (selectionId: number) => `/selection/${selectionId}`,
    interestHistory: (selectionId: number) => getSelectionPrefix(`${selectionId}/interest/list`),
  },
};
