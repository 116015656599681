import { ToggleModel } from '@/models/ToggleModel';

import FixationModalStore, { IFixationModalStore } from '../FixationModalStore';
import { IRootStore } from '../RootStore';

import { ILeadsStore, LeadModalStore } from './LeadModalStore';
import { ILotDetailModalStore, LotDetailModalStore } from './LotDetailModalStore';
import { IPWATutorialModalStore, PWATutorialModalStore } from './PWATutorialModalStore';

export interface IModalsStore {
  /** Модалка выпадающего меню навигации */
  menuModal: ToggleModel;
  /** Модалка списка уведомлений */
  notificationListModal: ToggleModel;
  /** Модалка с информацией о назначении встречи */
  meetingNoticeModal: ToggleModel;
  /** Модалка с детальной информацией о заявке */
  leadModalStore: ILeadsStore;
  /** Модалка с детальной информацией о лоте */
  lotDetailModalStore: ILotDetailModalStore;
  /** Модалка фиксации клиента */
  fixationModalStore: IFixationModalStore;
  /** Модалка туториала установки PWA */
  PWATutorialModalStore: IPWATutorialModalStore;
}

type Params = {
  rootStore: IRootStore;
};

export class ModalsStore implements IModalsStore {
  readonly menuModal = new ToggleModel();
  readonly notificationListModal = new ToggleModel();
  readonly meetingNoticeModal = new ToggleModel();
  readonly leadModalStore = new LeadModalStore();
  readonly lotDetailModalStore = new LotDetailModalStore();
  readonly PWATutorialModalStore = new PWATutorialModalStore();
  readonly fixationModalStore: FixationModalStore;

  constructor({ rootStore }: Params) {
    this.fixationModalStore = new FixationModalStore({ rootStore });
  }
}
