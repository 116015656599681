import { BaseClientModel } from '@/models/BaseClientModel';
import { Nullable, SelectOption } from '@/types/values';

import { CreateLeadResultType } from '../../modal';
import { BaseLeadFields, IBaseLead, LeadStatus } from '../base';

enum CustomLeadFields {
  crmRejectionInfo = 'crmRejectionInfo',
  client = 'client',
}
export const LeadFields = { ...CustomLeadFields, ...BaseLeadFields };
export type LeadFields = typeof LeadFields;

export type CrmRejectionInfo = Partial<{
  leadId: string;
  manager: {
    name: Nullable<string>;
    phone: Nullable<string>;
  };
  agency: {
    title: Nullable<string>;
  };
  message: Nullable<string>;
  holdDate: Nullable<string>;
}>;

export interface ILead extends IBaseLead {
  [LeadFields.crmRejectionInfo]: Nullable<CrmRejectionInfo>;
  [LeadFields.client]: BaseClientModel;
}

export const leadStatusEntities: Record<LeadStatus, SelectOption<LeadStatus>> = {
  [LeadStatus.unique]: {
    id: LeadStatus.unique,
    label: 'Уникален',
  },
  [LeadStatus.wasUnique]: {
    id: LeadStatus.wasUnique,
    label: 'Был уникален',
  },
  [LeadStatus.nonUnique]: {
    id: LeadStatus.nonUnique,
    label: 'Неуникален',
  },
};
export const leadStatusKeys: LeadStatus[] = [LeadStatus.unique, LeadStatus.wasUnique, LeadStatus.nonUnique];
export const leadStatusOptions: SelectOption<LeadStatus>[] = leadStatusKeys.map((key) => leadStatusEntities[key]);

export type LeadResultModalState =
  | { type: CreateLeadResultType.success }
  | { type: CreateLeadResultType.refused; crmRejectionInfo?: Nullable<CrmRejectionInfo> };
