import { ResultModalStubProps } from '@/components/ResultModalStub/ResultModalStub';
import { CreateLeadResultType } from '@/entities/modal';

import s from './CreateLeadResultModal.module.scss';

export const LEAD_RESULT_CONTENT_PROPS_CONFIG: Record<CreateLeadResultType, ResultModalStubProps> = {
  [CreateLeadResultType.success]: {
    title: 'Заявка принята',
    description: 'Клиент уникален и закреплён.',
    iconName: 'IconBusiness',
    iconHeight: 300,
  },
  [CreateLeadResultType.refused]: {
    title: 'Заявка отклонена',
    description: 'Клиент неуникален и не может быть закреплён.',
    iconName: 'IconRefusal',
    iconHeight: 195,
    iconClassName: s['content__refused-icon'],
  },
};
