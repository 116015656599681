import { LINKS } from '@/configs/app';
import { routerUrls } from '@/configs/routes';
import { IUserStore } from '@/stores/global/UserStore';

import { NavItemProps } from './types';

export enum NavItem {
  /** Главные элементы навигации */
  lots = 'lots',
  fixation = 'fixation',
  employees = 'employees',

  /** Элементы навигации из выпадающего списка */
  booking = 'booking',
  agency = 'agency',
  calculator = 'calculator',
  rateCard = 'rateCard',
  tour = 'tour',
}

export const NAV_ITEMS = {
  main: [NavItem.lots, NavItem.fixation, NavItem.employees],
  additional: [NavItem.booking, NavItem.agency, NavItem.calculator, NavItem.rateCard, NavItem.tour],
};

export const getLinksConfig = ({ userStore }: { userStore: IUserStore }): Record<NavItem, NavItemProps> => ({
  [NavItem.lots]: {
    text: 'Выбор лотов',
    link: { element: 'link', to: routerUrls.lots.create() },
    show: true,
    activeRoutes: [routerUrls.lots.mask],
  },
  [NavItem.fixation]: {
    text: 'Проверка уникальности',
    link: { element: 'link', to: routerUrls.clientList.create() },
    show: true,
    activeRoutes: [routerUrls.clientList.mask, routerUrls.leadList.mask],
  },
  [NavItem.employees]: {
    text: 'Управление доступами',
    link: { element: 'link', to: routerUrls.employeeList.create() },
    show: userStore.isAdmin,
    activeRoutes: [routerUrls.employeeList.mask],
  },
  [NavItem.booking]: {
    text: 'Бронирование лотов',
    link: { element: 'link', to: routerUrls.booking.create() },
    show: userStore.isFormaEmployee || userStore.isNotFormaDirector,
  },
  [NavItem.agency]: {
    text: 'Профиль компании',
    link: { element: 'link', to: routerUrls.agencyProfile.create() },
    show: userStore.isDirector && !userStore.isBlockedUser,
  },
  [NavItem.calculator]: {
    text: 'Расчет ипотеки',
    link: { element: 'a', href: LINKS.calculator, target: '_blank', rel: 'noopener noreferrer nofollow' },
    show: !userStore.isBlockedUser,
  },
  [NavItem.rateCard]: {
    text: 'Вознаграждение',
    link: { element: 'link', to: routerUrls.rateCard.create() },
    show: !userStore.isBlockedUser,
  },
  [NavItem.tour]: {
    text: 'Запись на брокер-тур',
    link: {
      element: 'a',
      href: LINKS.brokerTour,
      target: '_blank',
      rel: 'noopener noreferrer nofollow',
    },
    show: !userStore.isBlockedUser,
  },
});
