import cn from 'classnames';
import React, { useRef, useState } from 'react';

import AndroidPWATutorial from '@/assets/pwa-tutorial/AndroidPWATutorial.mp4';
import IOSPWATutorial from '@/assets/pwa-tutorial/IOSPWATutorial.mp4';
import IconButton from '@/components/IconButton';
import { KeyboardKey, useKeyDown } from '@/hooks/useKeyDown';

import s from './VideoCarousel.module.scss';

const VideoCarousel: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const videoRefs = useRef<HTMLVideoElement[]>([]);

  const videos = [IOSPWATutorial, AndroidPWATutorial];

  const toggleSlide = () => {
    setActiveIndex((prev) => (prev + 1) % videos.length);
  };

  useKeyDown({ func: toggleSlide, key: KeyboardKey.left });
  useKeyDown({ func: toggleSlide, key: KeyboardKey.right });

  React.useEffect(() => {
    videoRefs.current.forEach((video, idx) => {
      if (idx === activeIndex) {
        video.play();
      } else {
        video.pause();
      }
    });
  }, [activeIndex]);

  return (
    <div className={s.carousel}>
      <IconButton size={24} iconName="IconArrowLeft" onClick={toggleSlide} className={s.carousel__btn} />
      <div className={s.carousel__wrapper}>
        {videos.map((videoSrc, idx) => (
          <video
            key={idx}
            ref={(el: HTMLVideoElement) => {
              if (el) {
                videoRefs.current[idx] = el;
              }
            }}
            src={videoSrc}
            autoPlay
            muted
            loop
            className={cn(s.carousel__video, idx === activeIndex && s.carousel__video_active)}
          />
        ))}
      </div>
      <IconButton
        size={24}
        iconRotation={180}
        iconName="IconArrowLeft"
        onClick={toggleSlide}
        className={s.carousel__btn}
      />
    </div>
  );
};

export default React.memo(VideoCarousel);
