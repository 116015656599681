type BaseNotificationServer<T extends NotificationType, E> = {
  id: number;
  title: string;
  type: T;
  text: string;
  read: boolean;
  created_date: string;
  extra: E;
};

export type FixationPeriodNotificationServer = BaseNotificationServer<
  NotificationType.fixationPeriod,
  {
    lead_id: number;
  }
>;

export type InterestBidCreatedNotificationServer = BaseNotificationServer<
  NotificationType.interestBidCreated,
  {
    client_id: number;
    lead_id: number;
  }
>;

export type OtherNotificationServer = BaseNotificationServer<NotificationType.other, null>;

export type NotificationServer =
  | FixationPeriodNotificationServer
  | InterestBidCreatedNotificationServer
  | OtherNotificationServer;

export type NotificationListResponse = {
  notifications: NotificationServer[];
  unread_count: number;
  total: number;
};

export enum NotificationDirection {
  top = 'top',
  bottom = 'bottom',
}

export enum NotificationType {
  /** Другое */
  other = 'other',
  /** Истечение срока фиксации */
  fixationPeriod = 'fixation_period',
  /** Отклик клиента на подборку */
  interestBidCreated = 'interest_bid_created',
}

export type NotificationPayload = {
  types: string;
  direction: NotificationDirection;
  limit: number;
  notification_id: number | null;
};
