import { IBaseLot } from '@/entities/lots/client';

export class BaseLotModel implements IBaseLot {
  readonly id;
  readonly area;
  readonly floor;
  readonly realPrice;
  readonly rooms;
  readonly bulk;
  readonly flatNumber;
  readonly sectionNumber;
  readonly placeType;
  readonly status;
  readonly currentPrice;

  constructor({
    id,
    area,
    floor,
    realPrice,
    rooms,
    bulk,
    flatNumber,
    sectionNumber,
    placeType,
    status,
    currentPrice,
  }: IBaseLot) {
    this.id = id;
    this.area = area;
    this.floor = floor;
    this.realPrice = realPrice;
    this.rooms = rooms;
    this.bulk = bulk;
    this.flatNumber = flatNumber;
    this.sectionNumber = sectionNumber;
    this.placeType = placeType;
    this.status = status;
    this.currentPrice = currentPrice;
  }
}
