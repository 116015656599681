import * as React from 'react';

import BaseClickable from '@/components/BaseClickable';
import Typo, { TypoView } from '@/components/Typo';

import { NavItemProps } from '../../../types';

import s from './NavItemAdditional.module.scss';

type NavItemAdditionalProps = {
  onClick: VoidFunction;
};

const NavItemAdditional: React.FC<NavItemProps & NavItemAdditionalProps> = ({ onClick, text, link }) => {
  return (
    <BaseClickable {...link} onClick={onClick} className={s.item}>
      <Typo view={TypoView.text} color="black">
        {text}
      </Typo>
    </BaseClickable>
  );
};

export default React.memo(NavItemAdditional);
