import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Checkbox from '@/components/form/Checkbox';
import { DoubleField } from '@/components/form/DoubleField';
import InputField from '@/components/form/InputField';
import PhoneField from '@/components/form/PhoneField';
import Select from '@/components/form/Select';
import Typo, { TypoElement, TypoView } from '@/components/Typo';
import { ManagerFormModel } from '@/models/ManagerFormModel/ManagerFormModel';
import { useUiStore } from '@/stores/global/RootStore';

import s from './ManagerForm.module.scss';

type Props = {
  formModel: ManagerFormModel;
};

const ManagerForm: React.FC<Props> = ({ formModel }) => {
  const { dictionaries, isManagerRole, managerId, isSubAgent, changeIsSubAgent, subAgentFullName, subAgentPhone } =
    formModel;
  const { isPhone } = useUiStore();

  if (isManagerRole) {
    return null;
  }

  return (
    <div className={s['manager-form']}>
      <Typo
        className={s['manager-form__title']}
        view={isPhone ? TypoView.h4 : TypoView.h3}
        element={isPhone ? TypoElement.h4 : TypoElement.h3}
      >
        Ответственный
      </Typo>
      {!isSubAgent.props.checked && (
        <Select
          className={s['manager-form__select']}
          variant="vertical"
          multiple={false}
          options={dictionaries.employees.items}
          size="large"
          {...managerId.props}
        />
      )}
      <Checkbox {...isSubAgent.props} onChange={changeIsSubAgent} />
      {isSubAgent.props.checked && (
        <DoubleField>
          <InputField {...subAgentFullName.props} />
          <PhoneField {...subAgentPhone.props} />
        </DoubleField>
      )}
    </div>
  );
};

export default observer(ManagerForm);
