import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonType } from '@/components/Button';
import DialogStub from '@/components/DialogStub';
import Loader from '@/components/Loader';
import LotTag from '@/components/lots/LotTag';
import Typo, { TypoElement, TypoView, TypoWeight } from '@/components/Typo';
import { useRootStore } from '@/stores/global/RootStore';
import { formatMMYYYY } from '@/utils/formatDate';
import { formatMoney } from '@/utils/formatMoney';

import LotImage from './LotImage';

import s from './ModalContent.module.scss';

const ModalContent: React.FC = () => {
  const {
    modalsStore: { lotDetailModalStore },
  } = useRootStore();

  const { loadingStage, lot, detailLotModalTitle, buttonText, onClick } = lotDetailModalStore;

  const lotItem = lot.value;

  if (loadingStage.isLoading) {
    return (
      <div className={s.content__stub}>
        <Loader />
      </div>
    );
  }

  if (loadingStage.isError || !lotItem) {
    return (
      <div className={s.content__stub}>
        <DialogStub text="Что-то пошло не так. Пожалуйста, попробуйте позже" />
      </div>
    );
  }

  return (
    <div className={s.content}>
      <div className={cn(s.content__info, !buttonText && s['content__info_single'])}>
        <Typo view={TypoView.h4} className={s.content__title}>
          {detailLotModalTitle}
        </Typo>
        <Typo className={s.content__subtitle} element={TypoElement.p} view={TypoView.text}>
          {lotItem.floor}
          {'\u00A0'}этаж • №{'\u00A0'}
          {lotItem.flatNumber} •{'\u00A0'}Корпус{'\u00A0'}
          {lotItem.bulk.label} •{'\u00A0'}
          {lotItem.sectionNumber}
          {'\u00A0'}секция
          {lotItem.bulk.keyDate ? `${'\u00A0'}•${'\u00A0'}Ключи до ${formatMMYYYY(lotItem.bulk.keyDate)}` : ''}
        </Typo>
        <div className={s.content__tags}>
          {lotItem.attributes.map((tag) => (
            <LotTag tag={tag} key={tag} tagWeight={TypoWeight.medium} className={s['content__tags-item']} />
          ))}
        </div>
        <div className={cn(s.content__price, !buttonText && s.content__price_offseted)}>
          <Typo view={TypoView.h2} className={s['content__price-discount']}>
            {formatMoney(lotItem.currentPrice)}
          </Typo>
          {lotItem.hasPriceDiff && (
            <Typo view={TypoView.h6} color="text-grey" className={s['content__price-initial']}>
              {formatMoney(lotItem.realPrice)}
            </Typo>
          )}
        </div>
      </div>
      {buttonText && (
        <div className={s['content__button-wrapper']}>
          <Button className={s['content__button']} view={ButtonType.primary} element="button" onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      )}

      <div className={s['content__image-wrapper']}>
        {lotItem.image ? (
          <LotImage className={s.content__image} src={lotItem.image} alt="Планировка" />
        ) : (
          <div className={s.content__stub}>Планировка отсутствует</div>
        )}
      </div>
    </div>
  );
};

export default observer(ModalContent);
