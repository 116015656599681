import cn from 'classnames';
import * as React from 'react';

import { LeadStatus } from '@/entities/lead/base';

import s from './LeadStatusMarker.module.scss';

type LeadStatusMarkerProps = {
  status: LeadStatus;
  size?: number;
  className?: string;
};

const LeadStatusMarker: React.FC<LeadStatusMarkerProps> = ({ status, size = 8, className }) => {
  return (
    <div
      className={cn(s.marker, s[`marker__${status}`], className)}
      style={{ '--marker-size': `${size}px` } as React.CSSProperties}
    />
  );
};

export default LeadStatusMarker;
