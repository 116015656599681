import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { FixationCreationMode, FixationCreationStage } from '@/stores/global/FixationModalStore';
import { useFixationModalStore } from '@/stores/global/RootStore';

import Modal, { ModalVariant } from '../Modal';
import { ErrorText } from '../Modal/ErrorText';
import ErrorModal from '../leads/ErrorModal';

import { COMP_MAP } from './config';

const FixationCreationModal: React.FC = () => {
  const {
    close,
    modalState: { isOpen },
    isServerError,
    resetServerError,
    leadCreationStore,
    buttons,
    stage,
    clientFixationStore: { clientPhone, debouncedLoadClients },
    mode,
  } = useFixationModalStore();

  React.useEffect(() => {
    if (!isOpen || mode === FixationCreationMode.skipClient) {
      return;
    }

    debouncedLoadClients({ reset: true });
  }, [clientPhone.value, isOpen]);

  return (
    <>
      <Modal
        {...buttons}
        variant={ModalVariant.RightWindow}
        header={stage !== FixationCreationStage.result ? 'Фиксация клиента' : ''}
        ariaLabel="Фиксация клиента"
        onClose={close}
        opened={isOpen}
        onCloseError={resetServerError}
        error={isServerError && <ErrorText />}
      >
        {COMP_MAP[stage]}
      </Modal>
      <ErrorModal opened={leadCreationStore.errorModalState.isOpen} onClose={leadCreationStore.errorModalState.close} />
    </>
  );
};

export default observer(FixationCreationModal);
