import * as React from 'react';

import Modal, { ModalVariant } from '@/components/Modal';
import ResultModalStub from '@/components/ResultModalStub';
import { LeadResultModalState } from '@/entities/lead/list';
import { CreateLeadResultType } from '@/entities/modal';

import LeadResultContent from '../LeadResultContent';

import { LEAD_RESULT_CONTENT_PROPS_CONFIG } from './config';

type Props = {
  state: LeadResultModalState | null;
  onClose: VoidFunction;
};

const CreateLeadResultModal: React.FC<Props> = ({ state, onClose }) => {
  const stateType = !state ? CreateLeadResultType.refused : state.type;
  const contentProps = LEAD_RESULT_CONTENT_PROPS_CONFIG[stateType];
  const crmRejectionInfo = state?.type === CreateLeadResultType.refused ? state.crmRejectionInfo : null;

  const description = crmRejectionInfo?.message || contentProps.description;

  return (
    <Modal
      variant={ModalVariant.RightWindow}
      opened={Boolean(state)}
      onClose={onClose}
      withBackdrop={false}
      ariaLabel={contentProps.title}
      isScrollable={false}
    >
      <ResultModalStub {...contentProps} description={description}>
        {crmRejectionInfo && <LeadResultContent data={crmRejectionInfo} />}
      </ResultModalStub>
    </Modal>
  );
};

export default React.memo(CreateLeadResultModal);
