import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Modal, { ModalVariant } from '@/components/Modal';
import { useRootStore } from '@/stores/global/RootStore';

import { ConfirmCloseButtonPosition } from '../Modal/Confirm/Confirm';

import ModalContent from './ModalContent';

import s from './LotDetailModal.module.scss';

const LotDetailModal: React.FC = () => {
  const {
    modalsStore: { lotDetailModalStore },
    uiStore: { isPhone, isTinyDesktop, isLessThanDesktop },
  } = useRootStore();

  return (
    <Modal
      ariaLabel={lotDetailModalStore.detailLotModalTitle}
      variant={isPhone ? ModalVariant.RightWindow : ModalVariant.Confirm}
      opened={lotDetailModalStore.detailLotModal.isOpen}
      onClose={lotDetailModalStore.close}
      className={s.modal}
      contentClassName={s.modal__content}
      buttonClassName={s.modal__button}
      withBackdrop
      closeButtonPosition={
        isTinyDesktop || isLessThanDesktop ? ConfirmCloseButtonPosition.within : ConfirmCloseButtonPosition.out
      }
    >
      <ModalContent />
    </Modal>
  );
};

export default observer(LotDetailModal);
