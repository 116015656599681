import * as React from 'react';

import Typo, { TypoElement, TypoView } from '@/components/Typo';

import ClientsInfiniteList from './ClientsInfiniteList';

import s from './ClientSearch.module.scss';

type ClientSearchProps = {
  listHeight: number;
};

const ClientSearch: React.FC<ClientSearchProps> = ({ listHeight }) => {
  return (
    <div>
      <Typo element={TypoElement.h3} view={TypoView.h3} block className={s.content__title}>
        Номер телефона клиента
      </Typo>
      <ClientsInfiniteList infiniteListHeight={listHeight} />
    </div>
  );
};

export default ClientSearch;
