import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/stores/global/RootStore';

import { getLinksConfig, NAV_ITEMS } from '../config';

import NavigationDropdown from './NavigationDropdown';
import NavItemMain from './NavItemMain';

import s from './HeaderNavigation.module.scss';

const HeaderNavigation: React.FC = () => {
  const { userStore } = useRootStore();

  const { authorized } = userStore;

  const { main, additional, linksConfig } = React.useMemo(() => {
    const linksConfig = getLinksConfig({ userStore });

    const main = NAV_ITEMS.main.filter((link) => linksConfig[link].show);
    const additional = NAV_ITEMS.additional.filter((link) => linksConfig[link].show);

    return { main, additional, linksConfig };
  }, [authorized]);

  return (
    <div className={s.nav}>
      <div className={s.nav__main}>
        {main.map((link) => (
          <NavItemMain key={link} {...linksConfig[link]} />
        ))}
      </div>
      <NavigationDropdown items={additional} linksConfig={linksConfig} />
    </div>
  );
};

export default observer(HeaderNavigation);
