import { computed, makeObservable } from 'mobx';

import { IBaseClient } from '@/entities/client/base';
import { ClientCreationPayload, IClientForm } from '@/entities/client/list';
import { InputModel } from '@/models/InputModel';
import { validateNotEmpty, validateNotEmptyEmail, validatePhoneNumber } from '@/utils/validators';

import { CreateClientFormFields, FIELDS_TO_VALIDATE } from './config';

type Params = {
  extraRequiredFields?: (
    | CreateClientFormFields.clientEmail
    | CreateClientFormFields.clientLastName
    | CreateClientFormFields.clientPatronymic
  )[];
};

export class CreateClientFormModel implements IClientForm {
  readonly [CreateClientFormFields.clientFirstName] = new InputModel({
    name: 'client_first_name',
    caption: 'Имя',
    required: true,
    initialValue: '',
    validators: [validateNotEmpty()],
  });

  readonly [CreateClientFormFields.clientLastName]: InputModel;

  readonly [CreateClientFormFields.clientPatronymic]: InputModel;

  readonly [CreateClientFormFields.clientPhone] = new InputModel({
    name: 'client_phone',
    caption: 'Телефон',
    required: true,
    initialValue: '',
    validators: [validateNotEmpty(), validatePhoneNumber()],
  });

  readonly [CreateClientFormFields.clientEmail]: InputModel;

  constructor({ extraRequiredFields = [] }: Params) {
    const isLastNameRequired = extraRequiredFields.includes(CreateClientFormFields.clientLastName);

    this[CreateClientFormFields.clientLastName] = new InputModel({
      name: 'client_last_name',
      caption: 'Фамилия',
      required: isLastNameRequired,
      initialValue: '',
      validators: isLastNameRequired ? [validateNotEmpty()] : [],
    });

    const isPatronymicRequired = extraRequiredFields.includes(CreateClientFormFields.clientPatronymic);

    this[CreateClientFormFields.clientPatronymic] = new InputModel({
      name: 'client_patronymic',
      caption: 'Отчество',
      required: isPatronymicRequired,
      initialValue: '',
      validators: isPatronymicRequired ? [validateNotEmpty()] : [],
    });

    const isEmailRequired = extraRequiredFields.includes(CreateClientFormFields.clientEmail);

    this[CreateClientFormFields.clientEmail] = new InputModel({
      name: 'client_email',
      caption: 'Email',
      required: isEmailRequired,
      initialValue: '',
      validators: isEmailRequired ? [validateNotEmpty(), validateNotEmptyEmail()] : [validateNotEmptyEmail()],
    });

    makeObservable<this, '_requiredFields'>(this, {
      isError: computed,
      isRequiredFieldsFilled: computed,
      _requiredFields: computed,
    });
  }

  get isError(): boolean {
    let isError = false;

    FIELDS_TO_VALIDATE.forEach((field) => {
      if (this[field].isError) {
        isError = true;
      }
    });

    return isError;
  }

  get isRequiredFieldsFilled(): boolean {
    return this._requiredFields.every((field) => !!this[field].value);
  }

  scrollToErrorField = () => {
    const errorField = FIELDS_TO_VALIDATE.find((field) => this[field].isError);

    if (errorField) {
      this[errorField].scrollToField();
    }
  };

  validate = (): boolean => {
    FIELDS_TO_VALIDATE.forEach((field) => {
      this[field].validate();
    });

    if (this.isError) {
      this.scrollToErrorField();
    }

    return this.isError;
  };

  toJson = (): ClientCreationPayload => {
    return {
      first_name: this[CreateClientFormFields.clientFirstName].value,
      last_name: this[CreateClientFormFields.clientLastName].value || null,
      patronymic: this[CreateClientFormFields.clientPatronymic].value || null,
      phone: this[CreateClientFormFields.clientPhone].value,
      email: this[CreateClientFormFields.clientEmail].value || null,
    };
  };

  reset = (): void => {
    FIELDS_TO_VALIDATE.forEach((field) => {
      this[field].reset();
    });
  };

  setDisableFields(value: boolean, fields?: CreateClientFormFields[]): void {
    fields
      ? fields
      : FIELDS_TO_VALIDATE.forEach((field) => {
          this[field].setDisabled(value);
        });
  }

  updateFields = (client: IBaseClient): void => {
    this[CreateClientFormFields.clientFirstName].change(client.firstName);
    this[CreateClientFormFields.clientLastName].change(client.lastName ?? '');
    this[CreateClientFormFields.clientPatronymic].change(client.patronymic ?? '');
    this[CreateClientFormFields.clientPhone].change(client.phone);
    this[CreateClientFormFields.clientEmail].change(client.email);
  };

  private get _requiredFields(): CreateClientFormFields[] {
    return FIELDS_TO_VALIDATE.filter((field) => this[field].required) as CreateClientFormFields[];
  }
}
