import * as React from 'react';

import logo from '@/assets/forma-logo.svg?url';
import logoLarge from '@/assets/forma-logo-large.svg?url';
import BaseClickable from '@/components/BaseClickable';
import Picture from '@/components/Picture';
import { routerUrls } from '@/configs/routes';

type LogoProps = {
  className?: string;
  isLarge?: boolean;
};

const Logo: React.FC<LogoProps> = ({ className, isLarge = false }) => {
  return (
    <BaseClickable className={className} element="link" to={routerUrls.lots.create()}>
      <Picture
        src={isLarge ? logoLarge : logo}
        alt="Логотип FORMA"
        width={isLarge ? 154 : 114}
        height={isLarge ? 33 : 24}
      />
    </BaseClickable>
  );
};

export default React.memo(Logo);
