import { observer } from 'mobx-react-lite';
import React from 'react';

import { useLocalStore } from '@kts-front/hooks';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import CountWrapper from '@/components/CountWrapper';
import Icon from '@/components/Icon';
import { useRootStore } from '@/stores/global/RootStore';
import { NotificationListStoreContext } from '@/stores/local/NotificationListStore';
import { NotificationListStore } from '@/stores/local/NotificationListStore/NotificationListStore';

import NotificationListModal from './NotificationListModal';

import s from './Notifications.module.scss';

type NotificationsProps = {
  className?: string;
};

const Notifications: React.FC<NotificationsProps> = ({ className }) => {
  const rootStore = useRootStore();

  const { store: notificationsStore } = useLocalStore(() => new NotificationListStore(rootStore));

  const handleOpen = React.useCallback(() => {
    rootStore.modalsStore.menuModal.close();
    notificationsStore.modalState.open();
  }, []);

  return (
    <NotificationListStoreContext.Provider value={notificationsStore}>
      <CountWrapper
        className={className}
        count={rootStore.userStore.user?.unreadNotificationsCount ?? null}
        onClick={handleOpen}
      >
        <Button
          className={s.notifications__btn}
          element="button"
          view={ButtonType.secondary}
          size={ButtonSize.custom}
          after={<Icon iconName="IconNotification" />}
        ></Button>
      </CountWrapper>
      <NotificationListModal />
    </NotificationListStoreContext.Provider>
  );
};

export default observer(Notifications);
