import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Typo, { TypoView } from '@/components/Typo';
import { PhoneNumber } from '@/models/PhoneNumber';
import { FixationCreationMode } from '@/stores/global/FixationModalStore';
import { useFixationModalStore } from '@/stores/global/RootStore';
import { getFullName } from '@/utils/getFullName';

import s from './ClientInfo.module.scss';

type Props = {
  className?: string;
};

const ClientInfo: React.FC<Props> = ({ className }: Props) => {
  const {
    clientFixationStore: {
      clientInfo: { firstName, lastName, phone, email },
    },
    goBackToClient,
    mode,
  } = useFixationModalStore();

  return (
    <div className={cn(className, s.info)}>
      <Typo view={TypoView.h6} color="text-grey" block className={s.info__row}>
        <span>{getFullName({ lastName, firstName })}</span>
        <span>&middot;</span>
        <span>{PhoneNumber.format(phone, false)}</span>
      </Typo>
      <div className={s.info__row}>
        {email && (
          <Typo view={TypoView.h6} color="text-grey">
            {email}
          </Typo>
        )}
        {mode === FixationCreationMode.full && (
          <Typo underline view={TypoView.text} color="grey-main" onClick={goBackToClient} className={s.info__change}>
            Изменить выбор
          </Typo>
        )}
      </div>
    </div>
  );
};

export default observer(ClientInfo);
