import * as React from 'react';
import PhoneInput from 'react-phone-number-input/input';

import { useToggleState } from '@kts-front/hooks';

import BaseField, { BaseFieldProps } from '../BaseField';

export type PhoneFieldProps = BaseFieldProps &
  Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange'> & {
    value: string;
    onChange: (value: string) => void;
  };

const PhoneField = (
  { className, onFocus, onBlur, onChange, disabled, error, caption, filled, isError, ...props }: PhoneFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement => {
  const { opened: active, open: focus, close: blur } = useToggleState();

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>): void => {
      focus();
      onFocus?.(e);
    },
    [focus, onFocus],
  );

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      blur();
      onBlur?.(e);
    },
    [blur, onBlur],
  );

  return (
    <BaseField
      className={className}
      caption={caption}
      disabled={disabled}
      active={active}
      error={error}
      isError={isError}
      filled={filled}
      required={props.required}
      ariaLabel="телефон"
    >
      <PhoneInput
        className="clean-input"
        defaultCountry="RU"
        value={props.value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={ref}
        placeholder={props.placeholder}
      />
    </BaseField>
  );
};

export default React.forwardRef(PhoneField) as typeof PhoneField;
