import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import Button, { ButtonSize, ButtonType } from '@/components/Button';
import Icon from '@/components/Icon';
import { useRootStore } from '@/stores/global/RootStore';

import { getLinksConfig, NAV_ITEMS } from '../config';

import MenuModal from './MenuModal';

import s from './HeaderBurgerMenu.module.scss';

const HeaderBurgerMenu: React.FC = () => {
  const { userStore, modalsStore } = useRootStore();

  const { authorized } = userStore;

  const isMenuOpened = modalsStore.menuModal.isOpen;

  const { mobile, linksConfig } = React.useMemo(() => {
    const linksConfig = getLinksConfig({ userStore });

    const mobile = [...NAV_ITEMS.main, ...NAV_ITEMS.additional].filter((link) => linksConfig[link].show);

    return { mobile, linksConfig };
  }, [authorized]);

  const handleOpen = React.useCallback(() => {
    modalsStore.notificationListModal.close();
    modalsStore.menuModal.open();
  }, [modalsStore.notificationListModal, modalsStore.menuModal]);

  return (
    <>
      <Button
        element="button"
        view={ButtonType.secondary}
        size={ButtonSize.small}
        after={isMenuOpened ? <Icon iconName="IconClose" size={20} /> : <Icon iconName="IconBurger" />}
        className={cn(s.menu__button, isMenuOpened && s.menu__button_overlayed)}
        onClick={isMenuOpened ? modalsStore.menuModal.close : handleOpen}
      />
      <MenuModal linksConfig={linksConfig} items={mobile} opened={isMenuOpened} onClose={modalsStore.menuModal.close} />
    </>
  );
};

export default observer(HeaderBurgerMenu);
